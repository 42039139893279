import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { readLinkS3AndCreateFile } from "helpers/utils"
import { create, includes, isUndefined, pick } from "lodash"
import {
  InviteeBuilComponentDetail,
  SendComponentInviteeRequest,
} from "pages/project-build/project-build.type"
import {
  getComponentSubBOMMiddleware,
  getPCBMiddleware,
  getPCBSubBOMMiddleware,
  getProjectComponentBOMMiddleware,
  getProjectComponentConversationBOMMiddleware,
  getProjectComponentOtherMiddleware,
} from "pages/project-component-detail/services"
import { PROJECT_COMPONENT_TYPE } from "pages/project-component/project-component.constant"
import { getStoreData, Stores } from "services/indexedDB"
import forge from "node-forge"
import { encryptionHelper } from "helpers/encryption"

export const useAddComponentInInvitee = () => {
  const createNewComponentHistoryById = async (
    component: InviteeBuilComponentDetail,
    conversationId: string
  ) => {
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string

    const localUserId = localStorage.getItem("user_id") as string

    const componentType = component.type

    let dataComponentRequest: SendComponentInviteeRequest | null = {
      commit: "",
      componentType,
    }
    const fileHashKeys: any = []
    const projectComponentHistoryId =
      component.project_component_history_share_id

    if (projectComponentHistoryId) {
      try {
        const privateKeyStores = await getStoreData<{
          id: string
          privateKey: string
        }>(Stores.PrivateKeys)
        const privateKeyByUserId =
          privateKeyStores.find((el) => el.id === localUserId)?.privateKey || ""
        const privateKey = forge.pki.privateKeyFromPem(privateKeyByUserId)
        const decryptedShareKey = privateKey.decrypt(
          component.shareKey || "",
          "RSA-OAEP"
        )

        if (componentType === PROJECT_COMPONENT_TYPE.PCB) {
          await sendComponentInviteePCB(
            projectComponentHistoryId,
            component.history_from || "",
            conversationId,
            decryptedShareKey,
            userVaultKey,
            dataComponentRequest,
            fileHashKeys,
            localUserId
          )
          if (dataComponentRequest.boomFile) {
            const dataPCBSubBom = await getPCBSubBOMMiddleware(
              projectComponentHistoryId,
              conversationId,
              decryptedShareKey
            )
            if (
              !isUndefined(dataPCBSubBom.mouser_data) &&
              dataPCBSubBom.mouser_data.length
            ) {
              const encryptedMouser = await encryptionController().encrypt(
                JSON.stringify(dataPCBSubBom.mouser_data),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                }
              )
              dataComponentRequest.mouser_data = encryptedMouser
            }
            if (
              !isUndefined(dataPCBSubBom.additional_json) &&
              dataPCBSubBom.additional_json.length
            ) {
              const newData: any = await Promise.all(
                dataPCBSubBom.additional_json.map(async (item) => {
                  const encryptedValues = await encryptionController().encrypt(
                    JSON.stringify(item.values),
                    {
                      dataType: "string",
                      type: "component-shared",
                      encryptionKey: userVaultKey,
                    }
                  )
                  return {
                    ...item,
                    values: encryptedValues,
                  }
                })
              )
              dataComponentRequest.additionalJson = newData
            }
          }
        }
        if (
          includes(
            [
              PROJECT_COMPONENT_TYPE.SOFTWARE,
              PROJECT_COMPONENT_TYPE.FIRMWARE,
              PROJECT_COMPONENT_TYPE.MECHANICAL,
              PROJECT_COMPONENT_TYPE.MISCELLANEOUS,
            ],
            component.type
          )
        ) {
          let type = "software"
          switch (component.type) {
            case PROJECT_COMPONENT_TYPE.MECHANICAL:
              type = "mechanical"
              break
            case PROJECT_COMPONENT_TYPE.FIRMWARE:
              type = "firmware"
              break
            case PROJECT_COMPONENT_TYPE.MISCELLANEOUS:
              type = "miscellaneous"
              break
          }
          const dataResOther = await sendComponentInviteeOther(
            projectComponentHistoryId,
            dataComponentRequest,
            type,
            fileHashKeys,
            decryptedShareKey,
            component.history_from || "",
            userVaultKey,
            localUserId,
            conversationId
          )

          if (
            component.type === PROJECT_COMPONENT_TYPE.MECHANICAL &&
            dataResOther?.bom_id
          ) {
            const dataBoom = await getComponentSubBOMMiddleware(
              projectComponentHistoryId,
              type,
              conversationId,
              decryptedShareKey
            )

            if (dataBoom.file) {
              const bomFileMechanical = await readLinkS3AndCreateFile(
                dataBoom.file,
                projectComponentHistoryId,
                decryptedShareKey,
                true,
                component.history_from || "",
                userVaultKey,
                localUserId,
                conversationId
              )
              if (bomFileMechanical) {
                dataComponentRequest.boomFile = {
                  file_name: bomFileMechanical.file_name,
                  file_hash: bomFileMechanical.file_hash,
                }
                fileHashKeys.push(bomFileMechanical)
              }
            }
            if (
              !isUndefined(dataBoom.mouser_data) &&
              dataBoom.mouser_data.length
            ) {
              const encryptedMouser = await encryptionController().encrypt(
                JSON.stringify(dataBoom.mouser_data),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                }
              )
              dataComponentRequest.mouser_data = encryptedMouser
            }
            if (
              !isUndefined(dataBoom.additional_json) &&
              dataBoom.additional_json.length
            ) {
              const newData: any = await Promise.all(
                dataBoom.additional_json.map(async (item) => {
                  const encryptedValues = await encryptionController().encrypt(
                    JSON.stringify(item.values),
                    {
                      dataType: "string",
                      type: "component-shared",
                      encryptionKey: userVaultKey,
                    }
                  )
                  return {
                    ...item,
                    values: encryptedValues,
                  }
                })
              )
              dataComponentRequest.additionalJson = newData
            }
          }
        }

        if (component.type === PROJECT_COMPONENT_TYPE.BOM) {
          const dataBOM = await getProjectComponentConversationBOMMiddleware(
            projectComponentHistoryId,
            conversationId,
            decryptedShareKey
          )

          if (dataBOM.file) {
            const bomFile = await readLinkS3AndCreateFile(
              dataBOM.file,
              projectComponentHistoryId,
              decryptedShareKey,
              true,
              component.history_from || "",
              userVaultKey,
              localUserId,
              conversationId
            )
            if (bomFile) {
              dataComponentRequest.boomFile = {
                file_name: bomFile.file_name,
                file_hash: bomFile.file_hash,
              }
              fileHashKeys.push(bomFile)
            }
          }
          if (!isUndefined(dataBOM.mouser_data) && dataBOM.mouser_data.length) {
            const encryptedMouser = await encryptionController().encrypt(
              JSON.stringify(dataBOM.mouser_data),
              {
                dataType: "string",
                type: "component-shared",
                encryptionKey: userVaultKey,
              }
            )
            dataComponentRequest.mouser_data = encryptedMouser
          }
          if (
            !isUndefined(dataBOM.additional_json) &&
            dataBOM.additional_json.length
          ) {
            const newData: any = await Promise.all(
              dataBOM.additional_json.map(async (item) => {
                const encryptedValues = await encryptionController().encrypt(
                  JSON.stringify(item.values),
                  {
                    dataType: "string",
                    type: "component-shared",
                    encryptionKey: userVaultKey,
                  }
                )
                return {
                  ...item,
                  values: encryptedValues,
                }
              })
            )
            dataComponentRequest.additionalJson = newData
          }
          if (dataBOM.projectComponentHistoryCommit) {
            const commit = dataBOM.projectComponentHistoryCommit
              ? await encryptionController().decrypt(
                  dataBOM.projectComponentHistoryCommit,
                  {
                    dataType: "string",
                    type: "component-shared",
                    encryptionKey: decryptedShareKey,
                  }
                )
              : ""
            const encryptCommit = commit
              ? await encryptionController().encrypt(commit, {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: userVaultKey,
                })
              : ""
            dataComponentRequest.commit = encryptCommit
          }
        }
      } catch (error) {
        dataComponentRequest = null
      }
    } else {
      dataComponentRequest = null
    }

    return {
      dataComponentRequest,
      fileHashKeys,
    }
  }
  const sendComponentInviteePCB = async (
    projectComponentHistoryId: string,
    historyFromId: string,
    conversationId: string,
    sharedKey: string,
    userVaultKey: string,
    dataComponentRequest: SendComponentInviteeRequest,
    fileHashKeys: any[],
    userId: string
  ) => {
    const dataPCBRes = await getPCBMiddleware(
      projectComponentHistoryId,
      conversationId
    )

    if (dataPCBRes?.assembly_file) {
      const assemblyFile = await readLinkS3AndCreateFile(
        dataPCBRes?.assembly_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (assemblyFile) {
        dataComponentRequest.assemblyFile = {
          file_name: assemblyFile.file_name,
          file_hash: assemblyFile.file_hash,
        }
        fileHashKeys.push(assemblyFile)
      }
    }
    if (dataPCBRes?.bom_file) {
      const bomFile = await readLinkS3AndCreateFile(
        dataPCBRes?.bom_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (bomFile) {
        dataComponentRequest.boomFile = {
          file_name: bomFile.file_name,
          file_hash: bomFile.file_hash,
        }
        fileHashKeys.push(bomFile)
      }
    }
    if (dataPCBRes?.stackup_file) {
      const stackupFile = await readLinkS3AndCreateFile(
        dataPCBRes?.stackup_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (stackupFile) {
        dataComponentRequest.stackupFile = {
          file_name: stackupFile.file_name,
          file_hash: stackupFile.file_hash,
        }
        fileHashKeys.push(stackupFile)
      }
    }
    if (dataPCBRes?.zip_file) {
      const gerberFile = await readLinkS3AndCreateFile(
        dataPCBRes?.zip_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (gerberFile) {
        dataComponentRequest.gerberFile = {
          file_name: gerberFile.file_name,
          file_hash: gerberFile.file_hash,
        }
        fileHashKeys.push(gerberFile)
      }
      if (dataPCBRes?.files.length) {
        const attachments = await Promise.all(
          dataPCBRes?.files.map((attachment) =>
            readLinkS3AndCreateFile(
              attachment.file,
              projectComponentHistoryId,
              sharedKey,
              true,
              historyFromId,
              userVaultKey,
              userId,
              conversationId
            )
          )
        )
        dataComponentRequest.files = attachments
          .filter((el) => el)
          .map((attachment) => {
            fileHashKeys.push(attachment)
            return {
              file_name: attachment.file_name,
              file_hash: attachment.file_hash,
            }
          })
      }
      if (dataPCBRes?.tempLayers.length) {
        const layers = await Promise.all(
          dataPCBRes?.tempLayers.map(async (layer) => {
            const fileHash = await readLinkS3AndCreateFile(
              layer.file,
              projectComponentHistoryId,
              sharedKey,
              false,
              historyFromId,
              userVaultKey,
              userId,
              conversationId
            )
            fileHashKeys.push(fileHash)
            return {
              ...layer,
              file_name_hash: fileHash.file_name,
            }
          })
        )
        dataComponentRequest.pcbLayers = layers.map((layer) => {
          return {
            ...pick(layer, [
              "color",
              "custom_sort",
              "side",
              "type",
              "file_name",
              "sequence",
              "sort_index",
              "file_name_hash",
            ]),
          }
        }) as any
      }
      if (dataPCBRes.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataPCBRes.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "component-shared",
            encryptionKey: sharedKey,
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: userVaultKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
      if (dataPCBRes.specifications.length) {
        const specifications: {
          specification_part_id: string
          value: string
        }[] = []
        let specificationsFiles: any[] = []
        dataPCBRes.specifications[0].parts.forEach(async (part) => {
          if (part.files && part.files.length) {
            specificationsFiles = specificationsFiles.concat(
              part.files.map((el) => {
                return {
                  ...el,
                  specification_part_id: part.id,
                }
              })
            )
          }

          specifications.push({
            specification_part_id: part.id,
            value: part.value,
          })
        })

        if (specifications.length) {
          dataComponentRequest.specifications = specifications
        }
        if (specificationsFiles.length) {
          const specificationsHashFiles = await Promise.all(
            specificationsFiles.map(async (elFile) => {
              const fileHash = await readLinkS3AndCreateFile(
                elFile.file,
                projectComponentHistoryId,
                sharedKey,
                true,
                historyFromId,
                userVaultKey,
                userId,
                conversationId
              )
              fileHashKeys.push(fileHash)
              return {
                file_name: fileHash.file_name,
                file_hash: fileHash.file_hash,
                specification_part_id: elFile.specification_part_id,
              }
            })
          )
          dataComponentRequest.specificationFiles = specificationsHashFiles
        }
      }
      if (dataPCBRes.specification_html_file) {
        dataComponentRequest.specification_html_files =
          dataPCBRes.specification_html_file
      }
    }
  }

  const sendComponentInviteeOther = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    type: string,
    fileHashKeys: any[],
    sharedKey: string,
    historyFromId: string,
    userVaultKey: string,
    userId: string,
    conversationId: string
  ) => {
    const dataRes = await getProjectComponentOtherMiddleware(
      projectComponentHistoryId,
      type,
      conversationId,
      sharedKey
    )
    if (dataRes?.files.length) {
      const attachments = await Promise.all(
        dataRes?.files.map((attachment) =>
          readLinkS3AndCreateFile(
            attachment.file,
            projectComponentHistoryId,
            sharedKey,
            true,
            historyFromId,
            userVaultKey,
            userId,
            conversationId
          )
        )
      )
      dataComponentRequest.files = attachments
        .filter((el) => el)
        .map((attachment) => {
          fileHashKeys.push(attachment)
          return {
            file_name: attachment.file_name,
            file_hash: attachment.file_hash,
          }
        })
    }
    if (dataRes.projectComponentHistoryCommit) {
      const commit = await encryptionController().decrypt(
        dataRes.projectComponentHistoryCommit,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        }
      )
      const encryptCommit = await encryptionController().encrypt(commit, {
        dataType: "string",
        type: "component-shared",
        encryptionKey: userVaultKey,
      })
      dataComponentRequest.commit = encryptCommit
    }
    if (dataRes.description) {
      const encryptDescription = await encryptionController().encrypt(
        dataRes.description,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: userVaultKey,
        }
      )
      dataComponentRequest.description = encryptDescription
    }
    if (dataRes.three_d_file) {
      const threeDFile = await readLinkS3AndCreateFile(
        dataRes.three_d_file,
        projectComponentHistoryId,
        sharedKey,
        true,
        historyFromId,
        userVaultKey,
        userId,
        conversationId
      )
      if (threeDFile) {
        dataComponentRequest.threeDFile = {
          file_name: threeDFile.file_name,
          file_hash: threeDFile.file_hash,
        }
        fileHashKeys.push(threeDFile)
      }
    }
    return dataRes
  }

  const createNewComponentHistoryByOwner = async (
    projectId: string,
    componentType: number,
    projectComponentHistoryId: string
  ) => {
    const dataComponentRequest: SendComponentInviteeRequest = {
      commit: "",
      componentType,
    }

    const fileHashKeys: any = []

    const projectKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const projectKey = projectKeys[projectId]
    const sharedKey = await encryptionHelper.createRandomKey()
    const encryptSharedKey = await encryptionController().encrypt(sharedKey, {
      dataType: "string",
      type: "component-shared",
      encryptionKey: projectKey,
    })
    dataComponentRequest.encryptSharedKey = encryptSharedKey
    dataComponentRequest.sharedKey = sharedKey

    if (componentType === PROJECT_COMPONENT_TYPE.PCB) {
      await insertComponentInviteePCB(
        projectComponentHistoryId,
        dataComponentRequest,
        fileHashKeys,
        sharedKey
      )
      if (dataComponentRequest.boomFile) {
        const dataPCBSubBom = await getPCBSubBOMMiddleware(
          projectComponentHistoryId
        )
        if (
          !isUndefined(dataPCBSubBom.mouser_data) &&
          dataPCBSubBom.mouser_data.length
        ) {
          const encryptedMouser = await encryptionController().encrypt(
            JSON.stringify(dataPCBSubBom.mouser_data),
            {
              dataType: "string",
              type: "component-shared",
              encryptionKey: sharedKey,
            }
          )
          dataComponentRequest.mouser_data = encryptedMouser
        }
        if (
          !isUndefined(dataPCBSubBom.additional_json) &&
          dataPCBSubBom.additional_json.length
        ) {
          const newData: any = await Promise.all(
            dataPCBSubBom.additional_json.map(async (item) => {
              const encryptedValues = await encryptionController().encrypt(
                JSON.stringify(item.values),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: sharedKey,
                }
              )
              return {
                ...item,
                values: encryptedValues,
              }
            })
          )
          dataComponentRequest.additionalJson = newData
        }
      }
    }
    if (
      includes(
        [
          PROJECT_COMPONENT_TYPE.SOFTWARE,
          PROJECT_COMPONENT_TYPE.FIRMWARE,
          PROJECT_COMPONENT_TYPE.MECHANICAL,
          PROJECT_COMPONENT_TYPE.MISCELLANEOUS,
        ],
        componentType
      )
    ) {
      let type = "software"
      switch (componentType) {
        case PROJECT_COMPONENT_TYPE.MECHANICAL:
          type = "mechanical"
          break
        case PROJECT_COMPONENT_TYPE.FIRMWARE:
          type = "firmware"
          break
        case PROJECT_COMPONENT_TYPE.MISCELLANEOUS:
          type = "miscellaneous"
          break
      }
      const dataResOther = await insertComponentInviteeOther(
        projectComponentHistoryId,
        dataComponentRequest,
        type,
        fileHashKeys,
        sharedKey
      )
      if (
        componentType === PROJECT_COMPONENT_TYPE.MECHANICAL &&
        dataResOther.bom_id
      ) {
        const dataBoom = await getComponentSubBOMMiddleware(
          projectComponentHistoryId,
          type
        )

        if (dataBoom.file) {
          const bomFileMechanical = await readLinkS3AndCreateFile(
            dataBoom.file,
            projectComponentHistoryId,
            sharedKey
          )
          if (bomFileMechanical) {
            dataComponentRequest.boomFile = {
              file_name: bomFileMechanical.file_name,
              file_hash: bomFileMechanical.file_hash,
            }
            fileHashKeys.push(bomFileMechanical)
          }
        }
        if (!isUndefined(dataBoom.mouser_data) && dataBoom.mouser_data.length) {
          const encryptedMouser = await encryptionController().encrypt(
            JSON.stringify(dataBoom.mouser_data),
            {
              dataType: "string",
              type: "component-shared",
              encryptionKey: sharedKey,
            }
          )
          dataComponentRequest.mouser_data = encryptedMouser
        }
        if (
          !isUndefined(dataBoom.additional_json) &&
          dataBoom.additional_json.length
        ) {
          const newData: any = await Promise.all(
            dataBoom.additional_json.map(async (item) => {
              const encryptedValues = await encryptionController().encrypt(
                JSON.stringify(item.values),
                {
                  dataType: "string",
                  type: "component-shared",
                  encryptionKey: sharedKey,
                }
              )
              return {
                ...item,
                values: encryptedValues,
              }
            })
          )
          dataComponentRequest.additionalJson = newData
        }
      }
    }

    if (componentType === PROJECT_COMPONENT_TYPE.BOM) {
      const dataBOM = await getProjectComponentBOMMiddleware(
        projectComponentHistoryId
      )

      if (dataBOM.file) {
        const bomFile = await readLinkS3AndCreateFile(
          dataBOM.file,
          projectComponentHistoryId,
          sharedKey
        )
        if (bomFile) {
          dataComponentRequest.boomFile = {
            file_name: bomFile.file_name,
            file_hash: bomFile.file_hash,
          }
          fileHashKeys.push(bomFile)
        }
      }
      if (!isUndefined(dataBOM.mouser_data) && dataBOM.mouser_data.length) {
        const encryptedMouser = await encryptionController().encrypt(
          JSON.stringify(dataBOM.mouser_data),
          {
            dataType: "string",
            type: "component-shared",
            encryptionKey: sharedKey,
          }
        )
        dataComponentRequest.mouser_data = encryptedMouser
      }
      if (
        !isUndefined(dataBOM.additional_json) &&
        dataBOM.additional_json.length
      ) {
        const newData: any = await Promise.all(
          dataBOM.additional_json.map(async (item) => {
            const encryptedValues = await encryptionController().encrypt(
              JSON.stringify(item.values),
              {
                dataType: "string",
                type: "component-shared",
                encryptionKey: sharedKey,
              }
            )
            return {
              ...item,
              values: encryptedValues,
            }
          })
        )
        dataComponentRequest.additionalJson = newData
      }
      if (dataBOM.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataBOM.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "project",
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
    }
    return {
      dataComponentRequest,
      sharedKey,
      fileHashKeys,
    }
  }
  const insertComponentInviteePCB = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    fileHashKeys: any[],
    sharedKey: string
  ) => {
    const dataPCBRes = await getPCBMiddleware(projectComponentHistoryId)
    if (dataPCBRes?.assembly_file) {
      const assemblyFile = await readLinkS3AndCreateFile(
        dataPCBRes?.assembly_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (assemblyFile) {
        dataComponentRequest.assemblyFile = {
          file_name: assemblyFile.file_name,
          file_hash: assemblyFile.file_hash,
        }
        fileHashKeys.push(assemblyFile)
      }
    }
    if (dataPCBRes?.bom_file) {
      const bomFile = await readLinkS3AndCreateFile(
        dataPCBRes?.bom_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (bomFile) {
        dataComponentRequest.boomFile = {
          file_name: bomFile.file_name,
          file_hash: bomFile.file_hash,
        }
        fileHashKeys.push(bomFile)
      }
    }
    if (dataPCBRes?.stackup_file) {
      const stackupFile = await readLinkS3AndCreateFile(
        dataPCBRes?.stackup_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (stackupFile) {
        dataComponentRequest.stackupFile = {
          file_name: stackupFile.file_name,
          file_hash: stackupFile.file_hash,
        }
        fileHashKeys.push(stackupFile)
      }
    }
    if (dataPCBRes?.zip_file) {
      const gerberFile = await readLinkS3AndCreateFile(
        dataPCBRes?.zip_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (gerberFile) {
        dataComponentRequest.gerberFile = {
          file_name: gerberFile.file_name,
          file_hash: gerberFile.file_hash,
        }
        fileHashKeys.push(gerberFile)
      }
      if (dataPCBRes?.files.length) {
        const attachments = await Promise.all(
          dataPCBRes?.files.map((attachment) =>
            readLinkS3AndCreateFile(
              attachment.file,
              projectComponentHistoryId,
              sharedKey
            )
          )
        )
        dataComponentRequest.files = attachments
          .filter((el) => el)
          .map((attachment) => {
            fileHashKeys.push(attachment)
            return {
              file_name: attachment.file_name,
              file_hash: attachment.file_hash,
            }
          })
      }
      if (dataPCBRes?.tempLayers.length) {
        const layers = await Promise.all(
          dataPCBRes?.tempLayers.map(async (layer) => {
            const fileHash = await readLinkS3AndCreateFile(
              layer.file,
              projectComponentHistoryId,
              sharedKey,
              false
            )
            fileHashKeys.push(fileHash)
            return {
              ...layer,
              file_name_hash: fileHash.file_name,
            }
          })
        )
        dataComponentRequest.pcbLayers = layers.map((layer) => {
          return {
            ...pick(layer, [
              "color",
              "custom_sort",
              "side",
              "type",
              "file_name",
              "sequence",
              "sort_index",
              "file_name_hash",
            ]),
          }
        }) as any
      }
      if (dataPCBRes.projectComponentHistoryCommit) {
        const commit = await encryptionController().decrypt(
          dataPCBRes.projectComponentHistoryCommit,
          {
            dataType: "string",
            type: "project",
          }
        )
        const encryptCommit = await encryptionController().encrypt(commit, {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        })
        dataComponentRequest.commit = encryptCommit
      }
      if (dataPCBRes.specifications.length) {
        const specifications: {
          specification_part_id: string
          value: string
        }[] = []
        let specificationsFiles: any[] = []
        dataPCBRes.specifications[0].parts.forEach(async (part) => {
          if (part.files && part.files.length) {
            specificationsFiles = specificationsFiles.concat(
              part.files.map((el) => {
                return {
                  ...el,
                  specification_part_id: part.id,
                }
              })
            )
          }

          specifications.push({
            specification_part_id: part.id,
            value: part.value,
          })
        })

        if (specifications.length) {
          dataComponentRequest.specifications = specifications
        }
        if (specificationsFiles.length) {
          const specificationsHashFiles = await Promise.all(
            specificationsFiles.map(async (elFile) => {
              const fileHash = await readLinkS3AndCreateFile(
                elFile.file,
                projectComponentHistoryId,
                sharedKey
              )
              fileHashKeys.push(fileHash)
              return {
                file_name: fileHash.file_name,
                file_hash: fileHash.file_hash,
                specification_part_id: elFile.specification_part_id,
              }
            })
          )
          dataComponentRequest.specificationFiles = specificationsHashFiles
        }
      }
      if (dataPCBRes.specification_html_file) {
        dataComponentRequest.specification_html_files =
          dataPCBRes.specification_html_file
      }
    }
  }
  const insertComponentInviteeOther = async (
    projectComponentHistoryId: string,
    dataComponentRequest: SendComponentInviteeRequest,
    type: string,
    fileHashKeys: any[],
    sharedKey: string
  ) => {
    const dataRes = await getProjectComponentOtherMiddleware(
      projectComponentHistoryId,
      type
    )
    if (dataRes?.files.length) {
      const attachments = await Promise.all(
        dataRes?.files.map((attachment) =>
          readLinkS3AndCreateFile(
            attachment.file,
            projectComponentHistoryId,
            sharedKey
          )
        )
      )
      dataComponentRequest.files = attachments
        .filter((el) => el)
        .map((attachment) => {
          fileHashKeys.push(attachment)
          return {
            file_name: attachment.file_name,
            file_hash: attachment.file_hash,
          }
        })
    }
    if (dataRes.projectComponentHistoryCommit) {
      const commit = await encryptionController().decrypt(
        dataRes.projectComponentHistoryCommit,
        {
          dataType: "string",
          type: "project",
        }
      )
      const encryptCommit = await encryptionController().encrypt(commit, {
        dataType: "string",
        type: "component-shared",
        encryptionKey: sharedKey,
      })
      dataComponentRequest.commit = encryptCommit
    }
    if (dataRes.description) {
      const encryptDescription = await encryptionController().encrypt(
        dataRes.description,
        {
          dataType: "string",
          type: "component-shared",
          encryptionKey: sharedKey,
        }
      )
      dataComponentRequest.description = encryptDescription
    }
    if (dataRes.three_d_file) {
      const threeDFile = await readLinkS3AndCreateFile(
        dataRes.three_d_file,
        projectComponentHistoryId,
        sharedKey
      )
      if (threeDFile) {
        dataComponentRequest.threeDFile = {
          file_name: threeDFile.file_name,
          file_hash: threeDFile.file_hash,
        }
        fileHashKeys.push(threeDFile)
      }
    }
    return dataRes
  }
  return {
    createNewComponentHistoryById,
    createNewComponentHistoryByOwner,
  }
}
