import { STATUS_BUILD, BuildStatusNumber } from "components/Status/types"
import { HeaderTableBuild } from "pages/project-component/organisms/TableBuildCard"
import { ProjectComponentDetail } from "pages/project-component/types"
import {
  CategoryDetail,
  ProjectDetail,
  CategoriesProp,
} from "pages/projects/types"
import React from "react"
import { VERSION_APPROVED_STATUS } from "types"
const TYPE_COPPER = "copper"
const TYPE_SOLDERMASK = "soldermask"
const TYPE_SILKSCREEN = "silkscreen"
const TYPE_SOLDERPASTE = "solderpaste"
const TYPE_DRILL = "drill"
const TYPE_OUTLINE = "outline"
const TYPE_DRAWING = "drawing"

const SIDE_TOP = "top"
const SIDE_BOTTOM = "bottom"
const SIDE_INNER = "inner"
const SIDE_ALL = "all"

export interface HeaderProps {
  projectComponentDetail: ProjectComponentDetail
  projectDetail: ProjectDetail
  hiddenBorder?: boolean
  hash?: string
  projectBuildId?: string
  projectBuildName?: string
  projectBuildComponentId?: string
}

export interface BuildHistoryDetail {
  id: string
  project_component_id: string
  code: string
  commit: string
  description: string
  branch: string
  name: string
  created_at: string
  updated_at: string
  created_by: string
  builds: string[]
  isHasSpecification?: boolean
  status: STATUS_BUILD
  index?: number
  from_build_text?: string
  parent_id: string
  project_build_component_id: string
  type: string
  user: {
    id: string
    name: string
  }
  from_build_version_id: string
  from_build_component_id: string
  is_merged?: number
  transcended_from?: {
    project_component_history_id: string
    project_component_id: string
  }
  reverted_from: string
  build_status?: BuildStatusNumber
  tree_version_type?: string
  approved_status: VERSION_APPROVED_STATUS
  shareKey?: string
  decryptedShareKey?: string
  is_backup?: boolean
  is_owner?: boolean
}

export const defaultBuildHistoryDetail: BuildHistoryDetail = {
  id: "",
  project_component_id: "",
  name: "",
  created_at: "",
  updated_at: "",
  created_by: "",
  status: STATUS_BUILD.DRAFT,
  builds: [],
  isHasSpecification: false,
  branch: "",
  code: "",
  commit: "",
  description: "",
  parent_id: "",
  project_build_component_id: "",
  type: "",
  user: {
    id: "",
    name: "",
  },
  from_build_version_id: "",
  from_build_component_id: "",
  reverted_from: "",
  tree_version_type: "",
  approved_status: VERSION_APPROVED_STATUS.PENDING,
}

export interface ParamsBuildHistoryProps {
  page: number
  pageSize: number
}
export interface AppBarBuildHistoryProps {
  width?: number
  projectComponentDetail: ProjectComponentDetail
  isShowAppBar: boolean
  histories: BuildHistoryDetail[]
  onCloseAppBar: () => void
  buildHistoryActive: BuildHistoryDetail
  handleAddVersion?: () => void
  changeHistoryActive?: (item: BuildHistoryDetail) => () => void
  setComponentDetail?: (value: ProjectComponentDetail) => void
  fromPage?: ActionIconAppBarProps["fromPage"]
  readOnly?: boolean
}

export interface CommentDrawerProps {
  isShowComment: boolean
  onCloseAppBar: () => void
  idProjectComponent: string
}

export interface NoDataProps {
  handleAddVersion: (componentType: number) => void
  componentTypes: TypeSelectProp[]
  idProject: string
  readOnly?: boolean
}

export interface CreateBuildHistoryRequest {
  project_component_id: string
  project_component_type: number
}

export interface BuildHistoryCardProps {
  projectComponentDetail: ProjectComponentDetail
  buildHistory: BuildHistoryDetail
  layerAssignment: LayerAsignmentDetail[]
  isShowAppBar: {
    value: boolean
    setValue: (value: boolean) => void
  }
  isShowComment: {
    value: boolean
    setValue: (value: boolean) => void
  }
  isShare?: boolean
  hash?: string
  readOnly?: boolean
}

export interface HistoryItemProps {
  item: BuildHistoryDetail
  active: boolean
  changeHistoryActive?: (item: BuildHistoryDetail) => () => void
  handleDuplicateVersion?: (item: BuildHistoryDetail) => () => void
}

export interface OpenAppBarBuildHistoryProps {
  isShowAppBar: boolean
  handleChangeAppBar: () => void
}

export interface CreatePCBRequest {
  file: string
  file_name: string
}

export interface PCBCardProps {
  idHistory: string
  isShowAppBar: boolean
  layerAssignment: LayerAsignmentDetail[]
  isShare?: boolean
  hash?: string
  isHasSpecification?: boolean
  versionStatus: string
  readOnly?: boolean
}

export interface UpdatePCBFileProps {
  idHistory: string
  isShowAppBar: boolean
  handleUpdatePCB: () => void
  widthLoading?: number
  isAddFile?: boolean
}

export interface PCBShowImage {
  created_at: string
  file: string
  id: string
  pcb_id: string
  side: string
  viewbox: string
}

export interface PCBLayersDetail {
  color: string
  created_at: string
  file: string
  id: string
  pcb_id: string
  sequence: number
  side: string
  type: string
  show?: boolean
}
export interface PCBDetail {
  top: PCBShowImage
  bottom: PCBShowImage
  layers: Array<LayerRender>
  viewbox: number[]
  file_name: string
  bom_file: string
  bom_file_name: string
  stackup_file: string
  stackup_file_name: string
  assembly_file: string
  assembly_file_name: string
  zip_file: string
  specifications: PCBSpecificationSelection[]
  can_view_gerber: boolean
  files: FileUrlProp[]
  is_encrypted?: boolean
  tempLayers?: any
  projectComponentHistoryCommit?: string
  specification_html_file?: string
}

export const defaultPCBShowImage: PCBShowImage = {
  created_at: "",
  file: "",
  id: "",
  pcb_id: "",
  side: "",
  viewbox: "",
}

export const defaultPCBDetail: PCBDetail = {
  bottom: defaultPCBShowImage,
  top: defaultPCBShowImage,
  layers: [],
  viewbox: [],
  file_name: "",
  bom_file: "",
  bom_file_name: "",
  assembly_file: "",
  assembly_file_name: "",
  stackup_file: "",
  stackup_file_name: "",
  zip_file: "",
  specifications: [],
  can_view_gerber: true,
  files: [],
}

export interface TabPCBProps {
  active: number
  handleChangeTab: (index: number) => () => void
}

export interface LayersPCBProps {
  listPCB: PCBDetail
  activeTab: number
  layerVisibility: LayerVisibilityMap
  setLayerVisibility: (layer: LayerVisibilityMap) => void
  layerAssignment: LayerAsignmentDetail[]
}

export interface ChangeShowLayerCardProps {
  item: LayerRender[]
  handleChangeLayer: (index: number, show: boolean) => () => void
  isDisabled?: boolean
  layerVisibility: LayerVisibilityMap
}

export interface ChangeLayerAsignmentProps {
  item: LayerRender[]
  handleUpdateLayer: (
    idLayer: string,
    newSequence: number,
    oldSequence: number
  ) => void
  isDisabled?: boolean
  lisAsignment: LayerAsignmentDetail[]
}

export interface ProjectInVersionSpecification {
  id: string
  name: string
  categories: CategoriesProp[]
  created_at: string
  updated_at: string
}

export interface ComponentInVersionSpecification {
  id: string
  name: string
}

export interface HeaderVersionSpecificationProps {
  component: ComponentInVersionSpecification
  project: ProjectInVersionSpecification
  version: BuildHistoryDetail
  hash?: string
  projectBuildId?: string
  projectBuildComponentId?: string
  projectBuildName?: string
}

type Units = "mm" | "in" | ""
type CoordinateFormat = [number, number]
type ZeroSuppression = "L" | "T"
export type SvgSource = string | null

export interface LayerOptions {
  coordinateFormat: CoordinateFormat | null
  zeroSuppression: ZeroSuppression | null
  units: Units
}

export interface SvgRenderProps {
  source: SvgSource
  className?: string
}

type GerberType =
  | typeof TYPE_COPPER
  | typeof TYPE_SOLDERMASK
  | typeof TYPE_SILKSCREEN
  | typeof TYPE_SOLDERPASTE
  | typeof TYPE_DRILL
  | typeof TYPE_OUTLINE
  | typeof TYPE_DRAWING
  | null

type GerberSide =
  | typeof SIDE_TOP
  | typeof SIDE_BOTTOM
  | typeof SIDE_INNER
  | typeof SIDE_ALL
  | null

export type LayerRender = {
  id: string
  file_name: string
  type: GerberType
  side: GerberSide
  color: string
  file: any
  sequence: number
  sort_index: number
  custom_sort: boolean
}
export interface LayerVisibilityMap {
  [index: number]: boolean
}

export interface LayersRenderCardProps {
  layers: Array<LayerRender>
  layerVisibility: LayerVisibilityMap
  isEncrypted?: boolean
}

export interface LayerAsignmentDetail {
  key: string
  value: number
  label?: string
  sort_index: number
}

export interface PutLayerAssignmentRequest {
  id: string
  sequence: number
}
export interface SortLayerAssignmentRequest {
  layer_order_ids: string[]
}

export interface LayerImageRenderProps {
  url: string
  isBackground?: boolean
  classLoading?: string
  classBackground?: string
  sizeLoading?: number
  classImage?: string
}

export interface CustomCollapseProps {
  childrenBody: React.ReactNode
  isToggle?: boolean
  containerClass?: string
  childrenClass?: string
  header?: any
  buttonClass?: string
  hideBorderRadius?: boolean
  isDisableToggle?: boolean
  messageDisable?: string
  changeHeaderBorder?: boolean
  handleChangeToggle?: (newToggle: boolean) => void
}

export interface AnswersPartsDetail {
  id: string
  specification_part_id: string
  name: string
  icon: string
  placeholder: string
  sequence: number
  created_at: string
  helper_text: string
}

export interface FileEditorDetail {
  file: any
  file_type: string
  name: string
  id: string
  type?: string
  url?: string
}
export interface NewFileEditorDetail {
  file: File
  id: string
  type: "inline" | "outline"
}
export interface PartsSpecificationDetail {
  id: string
  parent_id: string
  specification_id: string
  name: string
  title: string
  image: string
  tooltip: string
  helper_text: string
  note: string
  sequence: number
  type: string
  layout: number
  created_at: string
  answers: AnswersPartsDetail[]
  placeholder: string
  parts: PartsSpecificationDetail[]
  value: any
  required: number
  files: FileEditorDetail[]
  has_see_stackup: boolean
}

export interface PCBSpecificationSelection {
  id: string
  name: string
  description: string
  note: string
  sequence: number
  created_at: string
  parts: PartsSpecificationDetail[]
}

export interface SpecificationCardProps {
  item: PCBSpecificationSelection
  disabled?: boolean
  conversationId?: string
  versionId?: string
  handleAddMultipartFile: any
}

export const TYPE_PARTS_SPECIFICATION = {
  SINGLE_CHECK: "single check",
  SINGLE_IMAGE_CHECK: "single image check",
  SINGLE_CHECK_OR_INPUT: "single check or input",
  SINGLE_CHECK_IMAGE: "single check image",
  DOUBLE_INPUT: "double input",
  SINGLE_INPUT: "single input",
  CHECKBOX: "checkbox",
  EDITOR: "editor",
  SELECT: "select",
  SINGLE_TWO_LINES_CHECK: "single two lines check",
  RADIO_BUTTON: "radio button",
  MULTIPLE_CHECK: "multiple check",
}

export interface SpecificationSingleCheckCardProps {
  item: PartsSpecificationDetail
  isInput?: boolean
  disabled?: boolean
}
export interface MultipleCheckSpecificationProps {
  item: PartsSpecificationDetail
  disabled?: boolean
}

export interface RootPartSingleCheckProps {
  name: string
  tooltip: string
  note: string
  childrenBody: React.ReactNode
  requiredName?: boolean
  rootClassInfo?: string
  styleRoot?: React.CSSProperties
}

export interface SpecificationSingleInputCardProps {
  item: PartsSpecificationDetail
  unit: string
  widthInput?: string
  minInput: number
  nameSpecification: string
  heightImage?: number
  widthImage?: number
  disabled?: boolean
}

export interface AdditionalOptionsSpecificationProps {
  item: PartsSpecificationDetail
  disabled?: boolean
}

export interface InfoSingleCardProps {
  name: string
  tooltip: string
  rootClass?: string
  colorName?:
    | "navy"
    | "orange"
    | "white"
    | "gray04"
    | "gray06"
    | "black"
    | "error"
    | "default"
  isHideMaxWidth?: boolean
  requiredName?: boolean
  styleName?: React.CSSProperties
}

export interface SpecificationEditorProps {
  item: PartsSpecificationDetail
  readOnly?: boolean
  conversationId?: string
  versionId?: string
  handleChangeFiles: any
}

export interface SingleTwoLinesCheckProps {
  item: PartsSpecificationDetail
  disabled?: boolean
}

export interface RadioButtonProps {
  item: PartsSpecificationDetail
  disabled?: boolean
}

export interface SelectSpecificationProps {
  item: PartsSpecificationDetail
  disabled?: boolean
}

export interface SpecificationHistoryRequest {
  specification_part_id: string
  value: any
  files?: {
    name: string
    file: string
  }[]
}

export interface DefaultProject {
  id: string
  name: string
  created_at: string
  updated_at: string
  project_build_component_id: string
  status: string
}

export const emptyDefaultProject: DefaultProject = {
  id: "",
  name: "",
  created_at: "",
  updated_at: "",
  project_build_component_id: "",
  status: "",
}

export interface PCBVersionSpecification {
  pcb_file_name: string
  category_name: string
  project: DefaultProject
  project_component: DefaultProject
  project_component_history: DefaultProject
  specifications: PCBSpecificationSelection[]
  category: CategoryDetail
}

export interface BackToBuildHistoryProps {
  idProjectComponent: string
  idProjectBuildComponent?: string
  idVersion: string
  hash: string
}

export type PostCommentBodyData = {
  content: string
}

// Other tabs
export interface FileUrlProp {
  file: string
  id: string
  name: string
}

export interface FileAddProp {
  file: string
  file_name: string
}

export interface TabContentBodyData {
  description?: string
  files?: FileAddProp[]
}

export interface GetTabContentMiddlewareData {
  id: string
  name: string
  created_at: string
  description: string
  files: FileUrlProp[]
  bom_id?: null | string
  three_d_file?: null | string
  three_d_file_name?: string
  projectComponentHistoryCommit?: string
}

export type TabType = "mechanical" | "software" | "firmware" | "miscellaneous"

export const dataEditorDefault = "<p><br></p>"

// component types
export type TypeSelectProp = {
  key: string
  value: number
}

export type ActionTypeProp = "Add" | "Commit" | "Revert" | "Transcend" | ""

export interface ActionIconAppBarProps {
  handleOnClickActionIcon: (type: ActionTypeProp) => void
  buildHistoryActive: BuildHistoryDetail
  fromPage?: "project-component" | "project-build-component"
  histories: BuildHistoryDetail[]
}

export enum ACTION_VERSION {
  ADD = "Add",
  COMMIT = "Commit",
  REVERT = "Revert",
  TRANSCEND = "Transcend",
}

export const STATUS_VERSION = {
  DRAFT: "Draft",
  COMMITTED: "Committed",
  MERGED: "Merged",
}

export interface InfoFileDetail {
  name: string
  file: string
  file_name?: string
  downloadUri?: string
}

export const emptyInfoFileDetail: InfoFileDetail = {
  name: "",
  file: "",
}

export interface BOMDetail {
  created_at: string
  updated_at: string
  file: string
  file_name: string
  id: string
  name: string
  bom_json: any
  mouser_data: any
  additional_json: any
  invitee_boms: any
  raw_bom_data: any
}
export const arrDoubleInputUnits = [
  {
    field: "mm",
  },
  {
    field: "inch'",
  },
]

export interface SelectCurrencyDetail {
  id: string
  name: string
  rate: number
  symbol: string
}
export const emptySelectCurrencyDetail: SelectCurrencyDetail = {
  id: "",
  name: "",
  rate: 0,
  symbol: "",
}

export enum TAB_URL_PROJECT_COMPONENT {
  COMPONENT = "component",
  BUILD_COMPONENT = "build-component",
}

export interface DataErrorWhenEditHistoryTree {
  message: string
  project_build_component_id: string
  project_component_id: string
  type: string
  statusCode: number
  build_code: string
}
export const emptyDataErrorWhenEditHistoryTree: DataErrorWhenEditHistoryTree = {
  message: "",
  project_build_component_id: "",
  project_component_id: "",
  type: "",
  statusCode: 0,
  build_code: "",
}

export interface TableColumnBOMDetail {
  key: string
  values: string[]
  idColumn: string
}

export interface UserWorkingItem {
  fullname: string
  id: string
  avatar: string
  email: string
}

export interface ParamUserWorkingItem {
  user_id: string
  url: string
}

export const ViewLinkedBuildHeaders: HeaderTableBuild[] = [
  {
    name: "#",
    field: "index",
    style: {
      width: 30,
      minWidth: 30,
    },
  },
  {
    name: "ID",
    field: "code",
    style: {
      width: 100,
      minWidth: 100,
    },
  },
  {
    name: "Build name",
    field: "name",
    style: {
      width: "15%",
      minWidth: "15%",
    },
  },
  {
    name: "Main version",
    field: "main_version",
    style: {
      width: "15%",
      minWidth: "15%",
    },
  },
  {
    name: "Branch version",
    field: "branch_version",
    style: {
      width: "15%",
      minWidth: "15%",
    },
  },
  {
    name: "Creation period",
    field: "created_at",
    style: {
      width: 170,
      minWidth: 170,
    },
  },
  {
    name: "Last update",
    field: "updated_at",
    style: {
      width: 170,
      minWidth: 170,
    },
  },
  {
    name: "Status",
    field: "status",
    style: {
      width: "calc(55% - 470px)",
      paddingRight: 12,
      textAlign: "end",
    },
  },
]

export enum ExtendedViewType {
  HideAll,
  Gerber,
  Bom,
  Mech3D,
}

export enum ComponentType {
  PCB = "pcb",
  BOM = "bom",
  Mechanical = "mechanical",
}
