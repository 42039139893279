import React, { useCallback, useEffect, useState } from "react"
import PageLayout from "pages/layout/PageLayout"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
  ProjectBuildDetail,
} from "pages/project-component/types"
import Header from "./organisms/ProjectComponentDetailHeader"
import {
  BuildHistoryDetail,
  defaultBuildHistoryDetail,
  emptyInfoFileDetail,
  InfoFileDetail,
  SelectCurrencyDetail,
  TAB_URL_PROJECT_COMPONENT,
  ViewLinkedBuildHeaders,
} from "./types"
import {
  deleteFileBOMMiddleware,
  getBOMCurrenciesMiddleware,
  getBuildHistoriesMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectBuildListLinkedBuildMiddleware,
  getProjectComponentBOMMiddleware,
  postBOMUpdateFileMiddleware,
  postInviteeBOMComponentSyncMiddleware,
} from "./services"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import InfoBuildDetail from "./organisms/InfoBuildDetail"
import HistoryTreeCard from "../../components/HistoryTree/HistoryTreeCard"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import UpdateFileItem from "./molecules/UpdateFileItem"
import { STATUS_TYPE_FILE_BUILD } from "./build-history.constant"
import { cloneDeep, includes, isArray } from "lodash"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import {
  checkPermissionPage,
  findHistoryTreeDefault,
  isTypeFile,
} from "helpers/utils"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { STATUS_RESPONSE } from "types"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { PermissionProjectComponentPage } from "./contexts/PermissionProjectComponentPage.context"
import BOMDetailCard from "./organisms/BOMDetailCard"
import TableBuildCard from "pages/project-component/organisms/TableBuildCard"
import { REACT_APP_API_CURRENCIES } from "config/environments"
import {
  emptyProjectBuildDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import { SectionCustom } from "components/Section/SectionCustom"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"

const BOMTemplate = () => {
  const params = useParams<{
    idProjectComponent: string
    titlePage: string
    idProjectBuildComponent: string
  }>()
  const { workerUploadFile } = useUploadFileWorkerController()
  const locationBOMTemplate = useLocation<any>()
  const idProjectComponent = params?.idProjectComponent || ""
  const titlePage = params?.titlePage || ""
  const idProjectBuildComponent = params?.idProjectBuildComponent || ""
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const idComponentHistory = useString()

  const [infoFileBOM, setInfoFileBOM] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [BOMJson, setBOMJson] = useState<any>([])
  const [mouserData, setMouserData] = useState<any>([])
  const [additionalJson, setadditionalJson] = useState<any>([])
  const [inviteeBoms, setInviteeBoms] = useState<any>([])
  const isDeleteFileBOM = useBoolean(false)
  const updatedAtComponent = useString()
  const viewOnlyShare = useBoolean(true)
  const archiveProject = useBoolean(true)
  const archiveComponent = useBoolean(true)
  const updatedAtBOM = useString("")
  const nameBOM = useString("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currencies, setCurrencies] = useState<SelectCurrencyDetail[]>([])
  const isViewLinkedBuild = useBoolean(false)
  const isActiveLinedBuild = useBoolean(false)
  const [listViewLinkedBuilds, setListViewLinkedBuilds] = useState<
    ProjectBuildDetail[]
  >([])
  const isOpenModalSyncData = useBoolean(false)
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const isReadComponent = useBoolean(true)
  const archiveBuild = useBoolean(true)
  const isUpdatingBom = useBoolean(false)
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  useEffect(() => {
    if (
      !idProjectComponent ||
      !includes(
        [
          TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
        ],
        titlePage
      )
    ) {
      pushTo(PATH.projects)
      return
    }
    getProjectComponentDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponent,
    titlePage,
    idProjectBuildComponent,
    locationBOMTemplate.state,
  ])

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponent
      )
      setComponentDetail(dataComponentRes)
      if (dataComponentRes.type.key !== TYPE_PROJECT_COMPONENT.BOM) {
        pushTo(PATH.projectComponent, {
          idProject: dataComponentRes.project_id,
        })
        return
      }
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataComponentRes.project_id
      )
      setProjectDetail(dataProjectDetailRes)
      const dataProjectRole = await getProjectRoleMiddleware(
        dataComponentRes.project_id
      )

      if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
        await getProjectBuildComponent(idProjectBuildComponent)
        await getIsReadProjectBuildComponent(idProjectBuildComponent)
      } else {
        setProjectBuild(emptyProjectBuildDetail)
        isReadComponent.setValue(dataComponentRes.is_read_comment)
        const dataLinkedBuild = await getProjectBuildListLinkedBuildMiddleware(
          dataComponentRes.id
        )
        isActiveLinedBuild.setValue(dataLinkedBuild.length)
        setListViewLinkedBuilds(dataLinkedBuild)
        archiveBuild.setValue(false)
        updatedAtComponent.setValue(dataComponentRes.updated_at)
      }
      await getHistories(dataComponentRes)
      await getBOMCurrencies()
      //
      viewOnlyShare.setValue(
        dataProjectRole.role === STATUS_PROJECT_ROLE.VIEWER
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      archiveComponent.setValue(Boolean(dataComponentRes.is_archived))
      nameBOM.setValue(dataComponentRes.name.replace(" ", "_"))
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }

  const getHistories = async (
    oldComponent: ProjectComponentDetail,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getBuildHistoriesMiddleware(
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? idProjectBuildComponent
          : oldComponent.id,
        titlePage
      )
      setHistories(dataRes)
      if (dataRes.length) {
        await getDataBOM(dataRes, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      pushTo(PATH.projects)
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const getDataBOM = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit: boolean
  ) => {
    const { idHistory, newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationBOMTemplate
    )
    setHistoryDetail(newHistoryDetail)
    idComponentHistory.setValue(idHistory)
    if (isDeleted) {
      return
    }
    await getBOM(idHistory)
  }
  const getBOMCurrencies = async () => {
    if (isDeleted) {
      return
    }
    const dataRes = await getBOMCurrenciesMiddleware(
      REACT_APP_API_CURRENCIES ? JSON.parse(REACT_APP_API_CURRENCIES) : []
    )
    setCurrencies(dataRes)
  }
  const getProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      oldIdProjectBuildComponent
    )
    setProjectBuild(dataRes)
    archiveBuild.setValue(Boolean(dataRes.is_archived))
    updatedAtComponent.setValue(dataRes.updated_at)
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent
    )
    isReadComponent.setValue(dataRes.is_read_comment)
  }

  const getBOM = useCallback(
    async (oldIdHistory: string, loading = false) => {
      try {
        const dataRes = await getProjectComponentBOMMiddleware(oldIdHistory)
        setInfoFileBOM({
          name: dataRes.file_name,
          file: dataRes.file,
        })
        const newTables = isArray(dataRes.bom_json)
          ? dataRes.bom_json
          : Object.values(dataRes.bom_json)[0]

        setBOMJson(newTables)
        setMouserData(dataRes.mouser_data)
        setadditionalJson(dataRes.additional_json)
        setInviteeBoms(
          isArray(dataRes.invitee_boms) ? dataRes.invitee_boms : []
        )
        isUpdatingBom.setValue(false)
        updatedAtBOM.setValue(dataRes.updated_at)
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        console.log("error", error)
        if (loading) {
          isLoading.setValue(false)
        }
      }
    },
    [isLoading, updatedAtBOM]
  )
  const updatedAtComponentDetail = (updatedAt: string) => {
    updatedAtComponent.setValue(updatedAt)
  }
  const handleUpdateFileBom = (newFile: FormData) => {
    if (!isTypeFile(Object(newFile.get("file")).name, "csv|xls|xlsx")) {
      toast(
        <LabelNotificationPage
          messenger={`${MESSENGER_NOTIFICATION.UPDATE_FILE_PCB_ERROR} csv,xls,xlsx`}
          type="warning"
        />
      )
      return
    }
    if (!idComponentHistory.value || !workerUploadFile) {
      return
    }
    isLoading.setValue(true)
    postBOMUpdateFileMiddleware(
      workerUploadFile,
      idComponentHistory.value,
      newFile,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getBOM(idComponentHistory.value, true)
          updatedAtComponentDetail(new Date().toISOString())
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }
  const onDeleteFileBOM = () => {
    isDeleteFileBOM.setValue(true)
  }

  const handleUpdateDataWhenChangeHistory = () => {
    isLoading.setValue(true)
    getHistories(componentDetail, true, false)
    updatedAtComponentDetail(new Date().toISOString())
  }

  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      isUpdatingBom.setValue(false)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getBOM(newHistoryDetail.id, true)
      isViewLinkedBuild.setValue(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idComponentHistory, isLoading, getBOM, isViewLinkedBuild, historyDetail]
  )
  const onSubmitDeleteFileBOM = () => {
    if (!idComponentHistory.value) {
      return
    }
    deleteFileBOMMiddleware(
      idComponentHistory.value,
      infoFileBOM.name,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getBOM(idComponentHistory.value, true)
          updatedAtComponentDetail(new Date().toISOString())
          isDeleteFileBOM.setValue(false)
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }
  const onUpdateDataEdit = (item: ProjectComponentDetail) => {
    setComponentDetail(item)
  }
  const onUpdateWhenAddSupplier = async (callback?: () => void) => {
    isLoading.setValue(true)
    await getBOM(historyDetail.id, true)
    if (callback) {
      callback()
    }
  }
  const onViewLinedBuilds = () => {
    if (!listViewLinkedBuilds.length || !isActiveLinedBuild.value) {
      return
    }
    isViewLinkedBuild.setValue(!isViewLinkedBuild.value)
  }

  const onSynComponent = () => {
    if (!idComponentHistory.value) {
      return
    }
    isLoading.setValue(true)
    postInviteeBOMComponentSyncMiddleware(
      idComponentHistory.value,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isOpenModalSyncData.setValue(false)
          getBOM(historyDetail.id, true)
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }
  const handleChangeHistoryTreeComment = (originIdHistory: string) => {
    const newHistories = cloneDeep(histories)
    const newHistoryDetail = newHistories.find(
      (el) => el.id === originIdHistory
    )
    if (newHistoryDetail) {
      handleChangeHistoryDetail(newHistoryDetail)()
    }
  }

  const renderBOMDetail = () => {
    if (isDeleted || !isArray(BOMJson) || !BOMJson.length) {
      return null
    }
    /// index of history
    const newMasterHistories =
      titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
        ? histories.filter((el) => !el.project_build_component_id)
        : histories
    const indexHistory = newMasterHistories.findIndex(
      (el) => el.id === historyDetail.id
    )
    return (
      <BOMDetailCard
        BOMJson={BOMJson}
        currencies={currencies}
        idHistory={historyDetail.id}
        updatedAtBOM={updatedAtBOM.value}
        onUpdateWhenAddSupplier={onUpdateWhenAddSupplier}
        nameBOM={nameBOM.value}
        bomFileName={infoFileBOM.name || ""}
        mouserData={isArray(mouserData) ? mouserData : []}
        additionalJson={isArray(additionalJson) ? additionalJson : []}
        inviteeBoms={isArray(inviteeBoms) ? inviteeBoms : []}
        isAddColumn={isUpdatingBom}
        isActiveBtnSync={
          (indexHistory === 0 && !historyDetail.project_build_component_id) || // first history => active
          historyDetail.status === STATUS_BUILD.DRAFT || // draft => always active
          // if index of history is second and the first is draft => active
          (indexHistory === 1 && histories[0]?.status === STATUS_BUILD.DRAFT)
        }
      />
    )
  }
  const renderViewLinkedBuild = () => {
    if (isViewLinkedBuild.value) {
      return (
        <div className="flex flex-col mt-6 mr-6">
          <TableBuildCard
            listBuilds={listViewLinkedBuilds}
            customHeaders={ViewLinkedBuildHeaders}
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="overflow-x-auto overflow-y-hidden min-h-[138px]">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mr-6 mt-6">
            <UpdateFileItem
              title={STATUS_TYPE_FILE_BUILD.BOM}
              handleUpdateFile={handleUpdateFileBom}
              containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
              file={infoFileBOM.file}
              fileName={infoFileBOM.name}
              onRemoveFile={onDeleteFileBOM}
              isAction={
                checkPermissionPage({
                  project: archiveProject.value,
                  viewShare: viewOnlyShare.value,
                  component: archiveComponent.value,
                  build: archiveBuild.value,
                }) ||
                includes(
                  [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
                  historyDetail.status
                )
              }
              isSyncData={
                !checkPermissionPage({
                  project: archiveProject.value,
                  viewShare: viewOnlyShare.value,
                  component: archiveComponent.value,
                  build: archiveBuild.value,
                }) &&
                historyDetail.status === STATUS_BUILD.COMMITTED &&
                titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT &&
                inviteeBoms.length
              }
              onClickSyncData={() => isOpenModalSyncData.setValue(true)}
              acceptFile=".csv,.xls,.xlsx"
              isDeleted={isDeleted}
              versionId={historyDetail.id}
            />
          </div>
        </div>

        {renderBOMDetail()}
      </React.Fragment>
    )
  }
  return (
    <PageLayout
      childrenHeader={
        <Header
          projectDetail={projectDetail}
          projectComponentDetail={componentDetail}
          updatedAtComponent={updatedAtComponent.value}
          titlePage={titlePage}
          projectBuild={projectBuild}
          idProjectBuildComponent={idProjectBuildComponent}
        />
      }
      heightHeader={48}
    >
      <PermissionProjectComponentPage.Provider
        value={{
          archiveProject: archiveProject.value,
          viewOnlyShare: viewOnlyShare.value,
          archiveComponent: archiveComponent.value,
          archiveBuild: archiveBuild.value,
          isListActionHistory:
            !viewOnlyShare.value && infoFileBOM.name ? true : false,
          isNoUpdateFilePCB: includes(
            [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
            historyDetail.status
          ),
          titlePage,
          idProjectBuildComponent,
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <InfoBuildDetail
            projectComponentDetail={componentDetail}
            projectBuild={projectBuild}
            historyDetail={historyDetail}
            handleUpdateDataWhenChangeHistory={
              handleUpdateDataWhenChangeHistory
            }
            setArchiveComponent={archiveComponent.setValue}
            histories={histories}
            onUpdateDataEdit={onUpdateDataEdit}
            isNotDownload
            isViewLinkedBuild={isViewLinkedBuild.value}
            isReadComponent={isReadComponent.value}
            setIsReadComponent={isReadComponent.setValue}
            onViewLinedBuilds={onViewLinedBuilds}
            isActiveLinedBuild={isActiveLinedBuild.value}
            handleChangeHistoryTreeComment={handleChangeHistoryTreeComment}
            isUpdatingBom={isUpdatingBom.value}
            isDeleted={isDeleted}
            commitButtonStage={{
              isActive:
                infoFileBOM.file &&
                infoFileBOM.name &&
                historyDetail.status === STATUS_BUILD.DRAFT
                  ? true
                  : false,
              tooltipHelper: "Please upload BOM",
            }}
          />

          <SectionCustom>
            <HistoryTreeCard
              histories={histories}
              historyDetail={historyDetail}
              handleChangeHistoryDetail={handleChangeHistoryDetail}
              typeComponent={componentDetail.type.key}
              titlePage={titlePage}
            />
            {renderViewLinkedBuild()}
          </SectionCustom>
        </div>

        {isDeleteFileBOM.value && (
          <ModalDelete
            onCloseModal={() => isDeleteFileBOM.setValue(false)}
            title={`Are you sure to delete file BOM?`}
            label={`Confirmation`}
            onSubmit={onSubmitDeleteFileBOM}
            titleButton="Delete"
          />
        )}
        {isOpenModalSyncData.value ? (
          <ModalDelete
            onCloseModal={() => isOpenModalSyncData.setValue(false)}
            title={`This action will reload the BOM detail table and remove all column added by invitee.`}
            content="You can not undo this action."
            label={`Confirmation`}
            onSubmit={onSynComponent}
            colorYellowButton
            titleButton="Confirm"
            styleTitle={{
              textAlign: "center",
              width: 300,
            }}
          />
        ) : null}
      </PermissionProjectComponentPage.Provider>
    </PageLayout>
  )
}

export default BOMTemplate
