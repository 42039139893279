export enum TAB_PROJECT_COMPONENT {
  COMPONENT = "component",
  BUILD = "build",
  FOLDER = "folder",
}
export enum TYPE_PROJECT_COMPONENT {
  PCB = "PCB",
  BOM = "BOM",
  MECHANICAL = "Mechanical",
  SOFTWARE = "Software",
  FIRMWARE = "Firmware",
  MISCELLANEOUS = "Miscellaneous",
}


export enum PROJECT_COMPONENT_TYPE {
  BOM = 1,
  FIRMWARE = 2,
  MECHANICAL = 3,
  MISCELLANEOUS = 4,
  SOFTWARE = 5,
  PCB = 6,
}

export const PROJECT_COMPONENT_TYPE_KEYS = {
  [PROJECT_COMPONENT_TYPE.BOM]: 'BOM',
  [PROJECT_COMPONENT_TYPE.FIRMWARE]: 'Firmware',
  [PROJECT_COMPONENT_TYPE.MECHANICAL]: 'Mechanical',
  [PROJECT_COMPONENT_TYPE.MISCELLANEOUS]: 'Miscellaneous',
  [PROJECT_COMPONENT_TYPE.SOFTWARE]: 'Software',
  [PROJECT_COMPONENT_TYPE.PCB]: 'PCB',
};