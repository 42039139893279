import { useEffect, useState } from "react"
import {
  ConversationMessagesDetail,
  ConversationMessagesDetailItem,
  emptyConversationMessagesDetail,
} from "./conversations.type"
import TabCoversationDetail from "./organisms/TabCoversationDetail"
import LogoMenu from "assets/images/logo-menu.svg"
import { ReactComponent as IconLogo } from "assets/images/logo-tracelium.svg"
import InfoNameAndEmailConversationCard from "./atoms/InfoNameAndEmailConversationCard"
import DotCard from "./atoms/DotCard"
import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import { useWindowSize } from "helpers/hooks"
import {
  getConversationKeyMiddleware,
  getConversationMessagesMiddleware,
} from "./conversations.api"
import { useParams } from "react-router-dom"
import { pushTo, useQuery } from "helpers/history"
import { encryptionHelper } from "helpers/encryption"
import { EncryptionKeys } from "controllers/EncryptionController"
import { isEmpty } from "lodash"

const SecretConversation = () => {
  const [conversationMessages, setConversationMessages] =
    useState<ConversationMessagesDetail>(emptyConversationMessagesDetail)
  const { isMobile } = useWindowSize()

  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)
  const [owner, setOwner] = useState<any>({})
  const params = useParams<{ idConversation: string }>()
  const query = useQuery()
  const conversationId = params.idConversation
  useEffect(() => {
    if (!params.idConversation) {
      pushTo("/")
      return
    }
    getInfo()
  }, [])
  const getInfo = async () => {
    try {
      const conversationInfo = await getConversationKeyMiddleware(
        conversationId
      )
      const accessToken = query.get("access_token")
      const encryptionKey = encryptionHelper.decrypt(
        accessToken || "",
        conversationInfo.encryptedKey
      )
      const buildProjectEncryptionKey = encryptionHelper.decrypt(
        accessToken || "",
        conversationInfo.buildProjectEncryptedKey
      )

      // if (encryptionKey && projectEncryptionKey) {
      const conversationEncryptionKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
      )
      localStorage.setItem(
        EncryptionKeys.conversationEncryptionKeys,
        JSON.stringify({
          ...conversationEncryptionKeys,
          [conversationId]: encryptionKey,
        })
      )
      localStorage.setItem(
        EncryptionKeys.currentConversationEncryptionKey,
        isEmpty(encryptionKey) ? "" : (encryptionKey as string)
      )
      //project
      // const projectEncryptionKeys = JSON.parse(
      //   localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
      // )
      // localStorage.setItem(
      //   EncryptionKeys.projectEncryptionKeys,
      //   JSON.stringify({
      //     ...projectEncryptionKeys,
      //     [projectId]: projectEncryptionKey,
      //   })
      // )

      const conversationMessages = await getConversationMessagesMiddleware(
        params.idConversation
      )
      setConversationMessages(conversationMessages)
    } catch (error) {
      pushTo("/")
    }
  }

  const onAddMessage = async (message: string) => {
    const conversationMessages = await getConversationMessagesMiddleware(
      params.idConversation
    )
    setConversationMessages(conversationMessages)
  }
  return (
    <div
      className={`relative flex flex-col flex-1 pb-6  overflow-hidden
   h-[calc(100_*_var(--vh)_-_52px)] md:h-[calc(100_*_var(--vh)_-_0px)]`}
    >
      <div className="h-full flex flex-col justify-between">
        <div className="w-auto h-14 flex flex-col bg-new-black-222222">
          <div className="flex items-center flex-1 w-full">
            <IconLogo className="h-6" />
          </div>
        </div>
        <div className="flex justify-between pr-6 border-b-[1px] px-6 py-2">
          <div className="flex items-center">
            <p className="font-normal text-13 leading-22 color-gray-7a mr-1">
              {"Replying to: "}
            </p>
            <p className="font-semibold text-13 leading-22 color-black-111111 max-w-150 md:max-w-461 overflow-hidden text-ellipsis">
              {conversationMessages.owner.fullname}
            </p>
            <DotCard />
            <p className="font-normal text-13 leading-22 color-gray-7a ">
              {conversationMessages.owner.email}
            </p>
          </div>
          <LabelHeader
            {...contentLabelStyleProps}
            labelClass="text-blue"
            containerClass="w-auto"
            title="Convo ID:"
            label={conversationMessages.conversation_code}
            isBorderRight={false}
          />
        </div>
        <div className="h-full flex flex-col overflow-auto pl-6 pt-4 ">
          <TabCoversationDetail
            idConversation={conversationId}
            originMessages={conversationMessages.messages}
            // only invitee is able to chat
            isEditorInput={true}
            updateWhenAddMessager={onAddMessage}
            inviteeUser={conversationMessages.invitee}
            onScrollMessage={() => {}}
            isChangeScrollMessageBottom={true}
            isDeleted={false}
            notYetCommented={true}
          />
        </div>
      </div>
      <div />
    </div>
  )
}
export default SecretConversation
