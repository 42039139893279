import ModalCustom from "components/ModalCustom"
import { ReactComponent as IconGoogleAuthen } from "assets/images/icons/google-authen.svg"
import Button from "components/Button/Button"

interface Props {
  onCloseModal: () => void
  onSetUpGoogleAuthenticator: () => void
}
export const WarningEnhanceAuthenticationModal = (props: Props) => {
  const { onCloseModal, onSetUpGoogleAuthenticator } = props

  const renderHeader = () => {
    return (
      <div className="flex">
        <IconGoogleAuthen />
        <p className="ml-[8px] text-[16px] text-[#222222] font-semibold">
          Enhance your security with two-factor authentication
        </p>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className="flex flex-col">
        <p className="text-[14px] text-[#222222] leading-[24px]">
          By default, you'll receive a code via email to verify your identity.
          For extra protection, enable Google Authenticator to generate secure
          codes on your device.
        </p>
        <div className="flex flex-col mt-[32px] md:flex-row gap-y-3 md:md-gap-y-0 items-center justify-between">
          <div className="w-full md:w-[100px] md:mr-[12px]">
            <Button
              widthBtn={"100%"}
              title="Later"
              colorBtn="white"
              onClick={onCloseModal}
            />
          </div>

          <Button
            widthBtn={"100%"}
            title="Set up Google Authenticator"
            onClick={onSetUpGoogleAuthenticator}
          />
        </div>
      </div>
    )
  }
  return (
    <ModalCustom
      label="Enhance security with two-factor authentication"
      widthModal={400}
      handleChangeButton={() => onCloseModal}
      styleHeaderModal={{
        height: "auto",
        paddingTop: 12,
        alignItems: "start",
      }}
      renderHeader={renderHeader()}
      bodyChildren={renderBody()}
    />
  )
}
