import axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  EncryptionKeys,
  encryptionController,
} from "controllers/EncryptionController"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { encryptionHelper } from "helpers/encryption"
import { validateEncryptedFile } from "helpers/utils"
import {
  BuildHistoryDetail,
  ComponentType,
  TableColumnBOMDetail,
} from "pages/project-component-detail/types"
import { STATUS_RESPONSE } from "types"
import { ACTION_WORKER } from "workers/type"
import { getWorkerLocalStorageData } from "workers/utils"

export const inviteeCommitMiddleware = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-component/history/${project_component_history_id}/commit`,
      {
        params: {
          conversation_id,
        },
      }
    )
  return response.data.data
}

export const inviteeCreateDraft = async (
  project_build_component_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-build-component/${project_build_component_id}/invitee/history/create-and-copy?conversation_id=${conversation_id}`,
      {
        log: {
          createNewDraftLog: activityLogHelper.toEncryptedMessage(
            ActivityLogType.CreateNewVersion,
            {}
          ),
          changeToDraftLog: activityLogHelper.toEncryptedMessage(
            ActivityLogType.ChangeVersionToDraft,
            {}
          ),
        },
      }
    )
  return response.data.data
}

export const approveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  componentCode: string,
  versionCode: string
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/approve`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.ApproveVersion,
        { versionCode }
      ),
      commentLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CommentApproveInviteeVersion,
        { componentCode, versionCode },
        conversationEncryptionKeys[conversation_id]
      ),
    }
  )
  return response.data.data
}
export const unapproveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  componentCode: string,
  versionCode: string
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/unapprove`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.UnapproveVersion,
        { versionCode }
      ),
      commentLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CommentUnapproveInviteeVersion,
        { componentCode, versionCode },
        conversationEncryptionKeys[conversation_id]
      ),
    }
  )
  return response.data.data
}
export const revertInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  data: {
    message: string
  }
) => {
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/revert`,
    data.message ? data : { message: "" }
  )
  return response.data.data
}

export const deleteInviteeDraft = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response = await axios.put(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/delete`,
    {
      log: {
        logInComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionInComponent,
          {}
        ),
        logOutComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionOutComponent,
          {}
        ),
      },
    }
  )
  return response.data.data
}

export const inviteeUploadFileBOMMiddleware = async (
  worker: Worker,
  idHistory: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.Upload3dFile,
        {
          type: "bom",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)

      axios
        .post(
          `/api/invitee/bom/upload/${idHistory}/${conversationId}`,
          request,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            { ...response.data.data.file, conversation_id: conversationId },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
          )
        })
    }
  }
}

export const inviteeDeleteFileBOMMiddleware = (
  idHistory: string,
  conversationId: string,
  fileName: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .put(`/api/invitee/bom/${idHistory}/${conversationId}/delete-file`, {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DeleteAttachmentFiles,
        { file: fileName }
      ),
    })
    .then((_responseDeleteFileBOM: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      )
    })
}
export const addInviteePcbFileMiddleware = (
  worker: Worker,
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type: "gerber",
    action: "uploaded",
    fileName: (request.get("file") as any).name,
  })
  request.append("log", log)
  axios
    .post(`/api/pcb/to-svg`, request)
    .then(async (response: AxiosResponse) => {
      const file = request.get("file") as File
      request.delete("file")
      worker.postMessage({
        action: ACTION_WORKER.START,
        payload: {
          isRawFile: true,
          files: [file],
          localStorageData: getWorkerLocalStorageData(),
          fileOption: {
            dataType: "file",
            type: "project",
          },
          dataSvgs: response.data.data,
        },
      })
      worker.onmessage = async function (e) {
        if (e.data.action === ACTION_WORKER.FINISH) {
          const { fileHashs, attachments, svgs, svgRequest } = e.data.payload
          const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
          attachments.forEach((attachment) => {
            request.append("file", attachment.blob, attachment.fileName)
          })

          svgRequest.forEach((elSvg) => {
            request.append("svg", elSvg.blob, elSvg.fileName)
          })

          request.append("file_hash", fileHash)
          request.append("layers", JSON.stringify(svgs))

          axios
            .post(
              `/api/invitee/pcb/upload-gerber/${projectComponentHistoryId}/${conversationId}`,
              request
            )
            .then(async (response: AxiosResponse) => {
              await validateEncryptedFile(
                fileHash,
                {
                  ...response.data.data.file,
                  conversation_id: conversationId,
                },
                response.data.data.project_id
              )
              callback(
                STATUS_RESPONSE.SUCCESS,
                MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
              )
            })
            .catch((error) => {
              callback(
                STATUS_RESPONSE.ERROR,
                error.response?.status === 413
                  ? `The upload of file larger than 50MB is not allowed`
                  : error.response?.data?.message ??
                      MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
              )
            })
        }
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
export const uploadInviteePcbBOMFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })

      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadSubBom,
        {
          type: "pcb",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)

      axios
        .post(
          `/api/invitee/pcb/upload-bom/${projectComponentHistoryId}/${conversationId}`,
          request
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              ...response.data.data.file,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
          )
        })
    }
  }
}
export const uploadInviteeStackupFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  conversationId: string,

  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadPcb,
        {
          type: "stackup",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)
      axios
        .post(
          `/api/invitee/pcb/upload-stackup/${projectComponentHistoryId}/${conversationId}`,
          request
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              file_key: response.data.data.file,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_ERROR
          )
        })
    }
  }
}
export const uploadInviteeAssemblyFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadPcb,
        {
          type: "assembly",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)
      axios
        .post(
          `/api/invitee/pcb/upload-assembly/${projectComponentHistoryId}/${conversationId}`,
          request
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              file_key: response.data.data.file,
              conversation_id: conversationId,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_ERROR
          )
        })
    }
  }
}
export const deleteInviteeFilePCBTemplateMiddleware = (
  idComponentHistory: string,
  conversationId: string,
  url: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void,
  fileName?: string,
  type?: string
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type,
    action: "deleted",
    fileName,
  })
  axios
    .put(`/api/invitee/pcb/${idComponentHistory}/${conversationId}/${url}`, {
      log,
    })
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, "")
      }
    })
    .catch((error) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR, error.response?.data?.message)
      }
    })
}
export const uploadInviteeAttachmentsMiddleware = async (
  worker: Worker,
  historyId: string,
  conversationId: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const files = data.getAll("files") as File[]
  data.delete("files")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: files,
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments, fileString } = e.data.payload
      attachments.forEach((attachment) => {
        data.append("files", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadAttachmentFiles,
        { file: fileString }
      )
      data.append("log", log)
      data.append("file_hashs", JSON.stringify(fileHashs))

      axios
        .post(
          `/api/invitee/pcb/upload-attachments/${historyId}/${conversationId}`,
          data
        )
        .then(async (response: AxiosResponse) => {
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                  conversation_id: conversationId,
                },
                response.data.data.project_id
              )
              return true
            })
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_ERROR
          )
        })
    }
  }
}

//mech
export const postInviteeTabContentMiddleware = async (
  worker: Worker,
  historyId: string,
  conversationId: string,
  type: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const description = data.get("description") as string
  if (description) {
    const encryptedMessage = await encryptionController().encrypt(description, {
      dataType: "string",
      type: "project",
    })
    data.append("description_block", encryptedMessage)
    const encryptedRawMessage = await encryptionController().encrypt(
      description,
      { dataType: "string", type: "project" }
    )
    data.delete("description")

    data.append("description", encryptedRawMessage)
  }
  data.append(
    "log",
    activityLogHelper.toEncryptedMessage(
      ActivityLogType.UploadAttachmentFiles,
      {}
    )
  )
  const files = data.getAll("files") as File[]

  data.delete("files")
  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: files,
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload

      attachments.forEach((attachment) => {
        data.append("files", attachment.blob, attachment.fileName)
      })
      data.append("file_hashs", JSON.stringify(fileHashs))
      axios
        .post<{ data: string }>(
          `/api/invitee/${type}/upload/${historyId}/${conversationId}`,
          data
        )
        .then(async (response: AxiosResponse) => {
          callback(STATUS_RESPONSE.SUCCESS, "")

          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                  conversation_id: conversationId,
                },
                response.data.data.project_id
              )
              return true
            })
          )
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

export const uploadInvitee3DFile = async (
  worker: Worker,
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const file = newFile.get("file") as any
  newFile.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        newFile.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.Upload3dFile,
        {
          fileName: file.name,
        }
      )
      newFile.append("log", log)
      newFile.append("file_hash", fileHash)

      axios
        .post(
          `/api/invitee/${type}/${historyId}/${conversationId}/upload-3d`,
          newFile
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            { ...response.data.data.file, conversation_id: conversationId },
            response.data.data.project_id
          )
          callback(STATUS_RESPONSE.SUCCESS, "")
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

export const deleteInvitee3DFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  fileName: string
) => {
  const logMessage = activityLogHelper.toEncryptedMessage(
    ActivityLogType.DeleteMech3DFile,
    {
      fileName: fileName,
    }
  )
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-3d-file`,
    {
      data: {
        log: logMessage,
      },
    }
  )
}
export const uploadInviteeSubBomFile = async (
  worker: Worker,
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const file = newFile.get("file") as any
  newFile.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        newFile.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadSubBom,
        {
          type,
          fileName: file.name,
        }
      )
      newFile.append("log", log)
      newFile.append("file_hash", fileHash)

      axios
        .post(
          `/api/invitee/${type}/${historyId}/${conversationId}/upload-bom`,
          newFile
        )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            { ...response.data.data.file, conversation_id: conversationId },
            response.data.data.project_id
          )
          callback(STATUS_RESPONSE.SUCCESS, "")
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

export const deleteInviteeSubBomFile = async (
  historyId: string,
  conversationId: string,
  type: string // TabType
) => {
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-bom`,
    {
      data: {
        log: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteMechBom,
          {}
        ),
      },
    }
  )
}

export const inviteeBomAddAdditionalColMiddleware = async (
  idHistory: string,
  conversationId: string,
  dataRequest: TableColumnBOMDetail[],
  type: ComponentType = ComponentType.BOM,
  oldData: TableColumnBOMDetail[]
) => {
  const diffColumn = activityLogHelper.toDiffColumn(oldData, dataRequest)
  const log = activityLogHelper.createAddColumnLog(type, diffColumn)
  const newData = await Promise.all(
    dataRequest.map(async (item) => {
      const encryptedValues = await encryptionController().encrypt(
        JSON.stringify(item.values),
        { dataType: "string", type: "project" }
      )
      return {
        ...item,
        values: encryptedValues,
      }
    })
  )
  const res = await axios.post(
    `/api/invitee/${type}/${idHistory}/${conversationId}/add-column`,
    {
      data: newData,
      log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddBomColumn, {
        content: log,
      }),
    }
  )
  return res.data.data
}
