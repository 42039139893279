import InputEditor2 from "components/Input/InputEditor2"
import { FileEditorDetail } from "pages/project-component-detail/types"

interface Props {
  handleChangeEditor: (description: string) => void
  dataEditor: string
  fileSelectedHandler: (
    files: FileList,
    type?: "inline" | "outline",
    imageUrl?: string,
    fileInLine?: FileList,
    fileInLineUrl?: { name: string; imageUrl: string }[]
  ) => void
  listFileAdd: FileEditorDetail[]
  keyInputFile: string
  handleDeleteFileNoDownload: (oldIndex: number) => () => void
  onSubmitButton: (dataEditor: string) => void
  onDisableButtonSubmit: boolean
  id?: string
}
const FormInputEditorCard = (props: Props) => {
  const {
    handleChangeEditor,
    dataEditor,
    fileSelectedHandler,
    listFileAdd,
    keyInputFile,
    handleDeleteFileNoDownload,
    onSubmitButton,
    onDisableButtonSubmit,
    id,
  } = props

  return (
    <div className="mt-3 mr-6 h-[195px]">
      <InputEditor2
        key={id}
        setValue={handleChangeEditor}
        value={dataEditor}
        handleAddFiles={fileSelectedHandler}
        listFileAdd={listFileAdd}
        keyInputFile={keyInputFile}
        handleDeleteFileNoDownload={handleDeleteFileNoDownload}
        styleRootListFile={{
          flexFlow: "inherit",
          overflow: "auto",
        }}
        styleFileEditorCard={{
          marginBottom: 0,
        }}
        styleIconAddFIle={{
          marginBottom: 6,
        }}
        styleRootCard={{
          marginBottom: 0,
        }}
        onSubmitButton={onSubmitButton}
        isDisableButton={onDisableButtonSubmit}
        conversationId={id}
      />
    </div>
  )
}
export default FormInputEditorCard
