import { STATUS_TYPE_FILE_BUILD } from "pages/project-component-detail/build-history.constant"
import {
  InfoFileDetail,
  ExtendedViewType,
  FileUrlProp,
} from "pages/project-component-detail/types"
import React, { useState } from "react"
import FileInviteePCBItem from "./FileInviteePCBItem"
import AttachmentOtherCard from "pages/project-component-detail/organisms/AttachmentOtherCard"
import FormSubComponentBOM from "pages/project-component-detail/organisms/FormSubComponentBOM"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import {
  handleCheckUpdateFileComponent,
  messageAndURLRemoveFileComponent,
} from "helpers/utils"
import { useBoolean } from "helpers/hooks"
import ModalDelete from "components/ModalCustom/ModalDelete"
import {
  addInviteePcbFileMiddleware,
  deleteInviteeFilePCBTemplateMiddleware,
  uploadInviteeAssemblyFileMiddleware,
  uploadInviteeAttachmentsMiddleware,
  uploadInviteePcbBOMFileMiddleware,
  uploadInviteeStackupFileMiddleware,
} from "./services/invitee.api"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"

interface Props {
  idComponentHistory: string
  infoFileGerber: InfoFileDetail
  infoFileBOM: InfoFileDetail
  infoFileStackup: InfoFileDetail
  infoFileAssembly: InfoFileDetail
  detailViewType: ExtendedViewType
  listFileUrl: FileUrlProp[]
  setDetailViewType: (type: ExtendedViewType) => void
  isDeleted?: boolean
  conversationId: string
  handleUpdateBomFile?: (idHistory: string) => void
  handleUpdateDataPCB: (idComponentHistory: string, type?: string) => void
  handleDeleteAttachment: (fileId: string) => void
  viewOnly: boolean
  decryptedShareKey?: string
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.idComponentHistory === nextProps.idComponentHistory &&
    prevProps.infoFileGerber === nextProps.infoFileGerber &&
    prevProps.infoFileBOM === nextProps.infoFileBOM &&
    prevProps.infoFileStackup === nextProps.infoFileStackup &&
    prevProps.infoFileAssembly === nextProps.infoFileAssembly &&
    prevProps.detailViewType === nextProps.detailViewType &&
    prevProps.isDeleted === nextProps.isDeleted &&
    prevProps.conversationId === nextProps.conversationId &&
    prevProps.listFileUrl === nextProps.listFileUrl &&
    prevProps.viewOnly === nextProps.viewOnly &&
    prevProps.decryptedShareKey === nextProps.decryptedShareKey
  )
}

const ListFileInviteeComponentPCBCard = React.memo((props: Props) => {
  const {
    idComponentHistory,
    infoFileAssembly,
    infoFileBOM,
    listFileUrl,
    infoFileGerber,
    infoFileStackup,
    detailViewType,
    setDetailViewType,
    isDeleted,
    conversationId,
    handleUpdateBomFile,
    handleDeleteAttachment,
    handleUpdateDataPCB,
    viewOnly,
    decryptedShareKey,
  } = props
  const { workerUploadFile } = useUploadFileWorkerController()

  const isLoading = useBoolean(false)
  const isDelete = useBoolean(false)
  const isFormSubComponent = useBoolean(false)
  const [typeFile, setTypeFile] = useState({
    status: "",
    urlDelete: "",
    messageSuccess: "",
    messageError: "",
  })

  const handleUpdateFileGerber = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "rar,zip,7z"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)
    addInviteePcbFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      conversationId,
      newFile,
      onChangeDataWhenUpdateFile
    )
    setDetailViewType(ExtendedViewType.Gerber)
  }

  const handleUpdateFileBom = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "csv,xls,xlsx",
        "csv|xls|xlsx"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)
    uploadInviteePcbBOMFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      conversationId,
      newFile,
      (type, message) => {
        if (handleUpdateBomFile) {
          handleUpdateBomFile(idComponentHistory)
        }
        onChangeDataWhenUpdateFile(type, message)
      }
    )
    setDetailViewType(ExtendedViewType.Bom)
  }
  const handleUpdateFileStackup = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        "pdf,png,jpg,jpeg",
        "pdf|png|jpg|jpeg"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    configureStore.dispatch(startLoading())
    isLoading.setValue(true)

    uploadInviteeStackupFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      conversationId,

      newFile,
      onChangeDataWhenUpdateFile
    )
  }
  const handleUpdateFileAssembly = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory,
        Object(newFile.get("file"))?.name,
        ".exe,.bin,.bat",
        "exe|bin|bat",
        true
      ) ||
      !workerUploadFile
    ) {
      return
    }
    configureStore.dispatch(startLoading())

    isLoading.setValue(true)

    uploadInviteeAssemblyFileMiddleware(
      workerUploadFile,
      idComponentHistory,
      conversationId,
      newFile,
      onChangeDataWhenUpdateFile
    )
  }
  const handleAddAttachments = (fileRequest: FormData, _fileErrors: File[]) => {
    if (!workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    configureStore.dispatch(startLoading())
    isLoading.setValue(true)
    uploadInviteeAttachmentsMiddleware(
      workerUploadFile,
      idComponentHistory,
      conversationId,
      fileRequest,
      onChangeDataWhenUpdateFile
    )
  }

  const onChangeDataWhenUpdateFile = (
    type: STATUS_RESPONSE,
    messenger: string
  ) => {
    if (type === STATUS_RESPONSE.SUCCESS) {
      handleUpdateDataPCB(idComponentHistory)
    }
    isLoading.setValue(false)
    toast(<LabelNotificationPage messenger={messenger} type={type} />)
  }

  const onRemoveFile = (type: STATUS_TYPE_FILE_BUILD) => {
    const { messageError, messageSuccess, urlDelete } =
      messageAndURLRemoveFileComponent(type)
    setTypeFile({
      status: type,
      messageSuccess,
      messageError,
      urlDelete,
    })
    isDelete.setValue(true)
  }
  const getFileNameAndType = (type: STATUS_TYPE_FILE_BUILD) => {
    let result = {
      pcbType: "gerber",
      fileName: infoFileGerber.name,
    }
    switch (type) {
      case STATUS_TYPE_FILE_BUILD.ASSEMBLY:
        result = {
          pcbType: "assembly",
          fileName: infoFileAssembly.name,
        }
        break
      case STATUS_TYPE_FILE_BUILD.STACKUP:
        result = {
          pcbType: "stackup",
          fileName: infoFileStackup.name,
        }
        break
      case STATUS_TYPE_FILE_BUILD.BOM:
        result = {
          pcbType: "bom",
          fileName: infoFileBOM.name,
        }
        break

      default:
        result = {
          pcbType: "gerber",
          fileName: infoFileGerber.name,
        }
        break
    }
    return result
  }
  const onSubmitDeleteFile = () => {
    if (!idComponentHistory) {
      return
    }
    isLoading.setValue(true)
    const { pcbType, fileName } = getFileNameAndType(typeFile.status as any)
    deleteInviteeFilePCBTemplateMiddleware(
      idComponentHistory,
      conversationId,
      typeFile.urlDelete,
      (type: STATUS_RESPONSE, messenger: string) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleUpdateDataPCB(idComponentHistory, typeFile.status)
          if (handleUpdateBomFile) {
            handleUpdateBomFile(idComponentHistory)
          }
          isDelete.setValue(false)
        }
        isLoading.setValue(false)
        const newMessenger =
          type === STATUS_RESPONSE.SUCCESS
            ? typeFile.messageSuccess
            : typeFile.messageError
        toast(
          <LabelNotificationPage
            messenger={messenger ? messenger : newMessenger}
            type={type}
          />
        )
      },
      fileName,
      pcbType
    )
  }

  const renderModal = () => {
    return (
      <React.Fragment>
        {isDelete.value && (
          <ModalDelete
            onCloseModal={() => isDelete.setValue(false)}
            title={`Are you sure to delete file ${typeFile.status}?`}
            label={`Confirmation`}
            onSubmit={onSubmitDeleteFile}
            titleButton="Delete"
          />
        )}
        {isFormSubComponent.value ? (
          <FormSubComponentBOM
            openModal={isFormSubComponent.value}
            onCloseModal={() => isFormSubComponent.setValue(false)}
            idHitory={idComponentHistory}
            nameBOM={infoFileBOM.name}
          />
        ) : null}
      </React.Fragment>
    )
  }
  return (
    <div className="overflow-hidden">
      <div className="overflow-auto h-full">
        <div className="mt-1 overflow-x-auto pb-2 md:pb-0 mr-6 md:mr-0">
          <div className="grid grid-cols-box-4-min-324 md:grid-cols-auto-4 mr-6 gap-2">
            <FileInviteePCBItem
              title={STATUS_TYPE_FILE_BUILD.GERBER}
              containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.GERBER}`}
              acceptFile=".rar,.zip,.7z"
              file={infoFileGerber.file}
              fileName={infoFileGerber.name}
              detailViewType={detailViewType}
              setDetailViewType={setDetailViewType}
              isDeleted={isDeleted}
              conversationId={conversationId}
              onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.GERBER)}
              handleUpdateFile={handleUpdateFileGerber}
              isAction={viewOnly}
              decryptedShareKey={decryptedShareKey}
            />
            <FileInviteePCBItem
              title={STATUS_TYPE_FILE_BUILD.BOM}
              containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
              acceptFile=".csv,.xls,.xlsx"
              file={infoFileBOM.file}
              fileName={infoFileBOM.name}
              detailViewType={detailViewType}
              setDetailViewType={setDetailViewType}
              downloadUri={infoFileBOM.downloadUri}
              isDeleted={isDeleted}
              conversationId={conversationId}
              onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.BOM)}
              handleUpdateFile={handleUpdateFileBom}
              isAction={viewOnly}
              decryptedShareKey={decryptedShareKey}
            />
            <FileInviteePCBItem
              title={STATUS_TYPE_FILE_BUILD.STACKUP}
              containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.STACKUP}`}
              acceptFile=".pdf,.png,.jpg,.jpeg"
              file={infoFileStackup.file}
              fileName={infoFileStackup.name}
              isDeleted={isDeleted}
              conversationId={conversationId}
              onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.STACKUP)}
              handleUpdateFile={handleUpdateFileStackup}
              isAction={viewOnly}
              decryptedShareKey={decryptedShareKey}
            />
            <FileInviteePCBItem
              title={STATUS_TYPE_FILE_BUILD.ASSEMBLY}
              containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.ASSEMBLY}`}
              acceptFile="*"
              file={infoFileAssembly.file}
              fileName={infoFileAssembly.name}
              isDeleted={isDeleted}
              conversationId={conversationId}
              onRemoveFile={() => onRemoveFile(STATUS_TYPE_FILE_BUILD.ASSEMBLY)}
              handleUpdateFile={handleUpdateFileAssembly}
              isAction={viewOnly}
              decryptedShareKey={decryptedShareKey}
            />
            {renderModal()}
          </div>
        </div>

        <div className=" mt-6">
          <AttachmentOtherCard
            listFileUrl={listFileUrl}
            viewOnly={viewOnly}
            typeOther={"pcb"}
            isDeleted={isDeleted}
            conversationId={conversationId}
            handleUpdateDataWhenDeleteFile={handleDeleteAttachment}
            handleUpdateDataWhenAddFile={handleAddAttachments}
            decryptedShareKey={decryptedShareKey}
          />
        </div>
      </div>
    </div>
  )
}, isEqualProps)
export default ListFileInviteeComponentPCBCard
