import { useEffect, useState } from "react"

export const useUploadFileWorkerController = () => {
  const [worker, setWorker] = useState<Worker | null>(null)

  useEffect(() => {
    // Import the worker file dynamically
    const newWorker = new Worker(
      new URL("./uploadFileWorker.ts", import.meta.url)
    )

    setWorker(newWorker)

    // Cleanup worker on component unmount
    return () => newWorker.terminate()
  }, [])

  return {
    workerUploadFile: worker,
  }
}
