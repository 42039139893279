import Tippy from "@tippyjs/react"
import LogoMenu from "assets/images/logo-menu.svg"
import { Avatar } from "components/Avatar"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import {
  REACT_APP_API_MENU_WEB,
  REACT_APP_API_VERSION,
  RELEASE_NOTE_URL,
} from "config/environments"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { includes } from "lodash"
import { setUserInfoAction } from "pages/auth/stores/actions"
import { emptyUserInfo, UserInfo } from "pages/auth/types"
import ActionItem from "pages/projects/molecules/ActionItem"
import { useLocation } from "react-router-dom"
import { configureStore } from "stores/configureStore"
import { MENU_LEFT } from "./layput.constants"
import LogoutCard from "./organisms/LogoutCard"
import ModalSearch from "./organisms/ModalSearch"
import { ACTION_LOGOUT } from "./types"

import { ReactComponent as CloseIcon } from "assets/images/icons/icon-close-24.svg"
import { ReactComponent as MenuBar } from "assets/images/icons/icon-editor-align-text-justify.svg"
import { ReactComponent as TraceliumLogo } from "assets/images/logo-tracelium-terms-policy.svg"
import Typography from "components/Typography"
import { NAME_COOKIE } from "constants/cookie"
import { useCookies } from "react-cookie"
import {
  UnreadBuild,
  UnreadConversation,
} from "pages/conversations/conversations.type"
import { Clock } from "components/Clock"

interface Props {
  userInfo: UserInfo
  unreadBuild: UnreadBuild
  unreadConversation: UnreadConversation
}
const UnreadIcon = (props: { totalUnread: number }) => {
  return props.totalUnread !== 0 ? (
    <div
      className=" flex items-center justify-center w-6 h-5 mr-[30px] md:w-5 md:h-4 md:absolute md:right-[14px] md:top-[2px] md:m-0 text-white z-10"
      style={{ background: "#EA4545", borderRadius: "999px" }}
    >
      <p
        className="font-body font-semibold px-1 py-[1px]"
        style={{ fontSize: "10px" }}
      >
        {props.totalUnread}
      </p>
    </div>
  ) : null
}
const MenuLeft = (props: Props) => {
  const { userInfo, unreadBuild, unreadConversation } = props

  const [_cookies, _setCookie, removeCookie] = useCookies([
    NAME_COOKIE.PROJECT,
    NAME_COOKIE.PROJECT_COMPONENT,
    NAME_COOKIE.CONVERSATIONS,
  ])

  const { isMobile } = useWindowSize()
  const { TippyLayout } = useTippyLayout()

  const location = useLocation() as any

  const pathnameLocation =
    location.pathname === "/" ? PATH.projects : location.pathname

  const showMenuTippy = useBoolean()
  const showLogout = useBoolean()
  const showMenuBar = useBoolean()
  const menuEnv = REACT_APP_API_MENU_WEB
    ? JSON.parse(REACT_APP_API_MENU_WEB)
    : []

  const menuLeft = menuEnv.length
    ? MENU_LEFT.filter((el) => includes(menuEnv, el.name))
    : MENU_LEFT

  const handleMenuPage = (newPath: string) => () => {
    if (newPath === "/setting") {
      pushTo("/generals", undefined, { tab: 3 })
    } else {
      if (location.pathname.indexOf(newPath) === 0) return
      pushTo(newPath)
    }
  }
  const handleClickTippy = (event) => {
    event.stopPropagation()
    event.preventDefault()
    showMenuTippy.setValue(!showMenuTippy.value)
  }

  const onClickAction = (key: "logout" | "myProfile") => () => {
    if (key === "myProfile") {
      pushTo(PATH.general, undefined, { tab: 0 })
      return
    }
    showLogout.setValue(true)
    showMenuTippy.setValue(false)
  }
  const handleChangeButton = (key: ACTION_LOGOUT) => () => {
    if (key === ACTION_LOGOUT.NO) {
      showLogout.setValue(false)
      return
    }

    // Store the value of enhanceAuthenticationUserId
    const enhanceAuthenticationUserId = localStorage.getItem(
      "enhanceAuthenticationUserId"
    )

    // Clear all localStorage
    localStorage.clear()

    // Restore the value of enhanceAuthenticationUserId
    if (enhanceAuthenticationUserId) {
      localStorage.setItem(
        "enhanceAuthenticationUserId",
        enhanceAuthenticationUserId
      )
    }

    /// clear project, component, build and conversation filter
    removeCookie(NAME_COOKIE.PROJECT)
    removeCookie(NAME_COOKIE.PROJECT_COMPONENT)
    removeCookie(NAME_COOKIE.CONVERSATIONS)

    configureStore.dispatch(setUserInfoAction(emptyUserInfo))
    pushTo(PATH.login)
  }

  const renderMainContent = () => (
    <div className="w-auto md:min-w-[72px] flex flex-col bg-new-black-222222 h-full">
      <div className="hidden md:flex w-full justify-center pt-5 pb-1">
        <img
          src={LogoMenu}
          alt="logo"
          className="w-24-custom h-24-custom rounded cursor-pointer"
          onClick={() => pushTo(PATH.projects)}
        />
      </div>
      <Clock />
      <div className="flex justify-center flex-col h-[calc(100_*_var(--vh)_-_52px)]">
        <ModalSearch />

        <div className="flex flex-col flex-1">
          <div className=" flex flex-col justify-start md:justify-center flex-1 pl-6 md:pl-0">
            {menuLeft.map((menu, index) => (
              <Tippy
                key={index}
                className="custom-tippy-menu-left flex-1"
                placement="right"
                content={
                  <span
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                    }}
                  >
                    {menu.name}
                  </span>
                }
              >
                <div
                  onClick={handleMenuPage(menu.path)}
                  className={`h-[60px] md:h-10 w-full flex items-center justify-between  md:justify-center cursor-pointer relative hover-menu-left ${
                    index + 1 < menuLeft.length && !isMobile ? "mb-3" : ""
                  } ${
                    pathnameLocation.includes(menu.path) ||
                    (menu.path === "/setting" && location.state?.tab === 3)
                      ? "active-menu-left"
                      : ""
                  }`}
                >
                  <div className="flex items-center">
                    <div>
                      <menu.icon />
                    </div>
                    <p className="block md:hidden font-semibold md:font-medium pl-2">
                      {menu.name}
                    </p>
                  </div>
                  {menu.name === "Conversations" && (
                    <UnreadIcon
                      totalUnread={
                        unreadConversation.unread_cc_to_me +
                        unreadConversation.unread_from_me +
                        unreadConversation.unread_to_me +
                        unreadConversation.unread_others
                      }
                    />
                  )}
                  {menu.name === "Builds" && (
                    <UnreadIcon
                      totalUnread={
                        unreadBuild.unread_my_build +
                        unreadBuild.unread_others_build
                      }
                    />
                  )}

                  <div
                    className="show-line absolute right-0 h-[60px] md:h-10 w-1 bg-new-yellow"
                    style={{
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  />
                </div>
              </Tippy>
            ))}
          </div>

          <a
            className="block md:hidden font-semibold pl-6 pb-6 cursor-pointer hover:underline"
            href="https://tawk.to/chat/647967db74285f0ec46f124e/1h1t3rcns"
          >
            Chat with us
          </a>
          <div className="flex justify-start md:justify-center pl-6 md:pl-0">
            <TippyCustomzie
              containerClass={"menu-bar-avatar component-card-menu"}
              placement="top-start"
              interactive
              arrow={false}
              animation="scale"
              visible={showMenuTippy.value}
              onClickOutside={() => showMenuTippy.setValue(false)}
              offset={[0, 5]}
              allowHTML
              content={
                <div
                  style={{
                    background: isMobile ? "#ffffff" : "#222222",
                    borderRadius: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 120,
                  }}
                >
                  <ActionItem
                    title="My profile"
                    onClick={onClickAction("myProfile")}
                    labelColor={isMobile ? "black" : "white"}
                  />
                  <ActionItem
                    title="Logout"
                    onClick={onClickAction("logout")}
                    labelColor={isMobile ? "black" : "white"}
                  />
                </div>
              }
            >
              <div className="flex flex-col items-center pb-[18px] md:pb-0 ">
                <div
                  className="cursor-pointer flex items-center "
                  onClick={handleClickTippy}
                >
                  <Avatar
                    name={userInfo.fullname ?? ""}
                    avatar={userInfo.avatar ?? ""}
                    width="24px"
                    height="24px"
                  />
                  {userInfo.fullname ? (
                    <Typography className="block md:hidden font-semibold md:font-medium pl-2">
                      {userInfo.fullname}
                    </Typography>
                  ) : null}
                </div>

                <p className="self-start md:self-center md:ml-0 md:text-center font-medium text-[12px] leading-[22px] text-[#C7C7C7] mt-[12px]">
                  <Tippy
                    key="tippy-logs"
                    offset={[0, 27]}
                    className="custom-tippy-menu-left"
                    placement="left"
                    content={<p>View update logs</p>}
                  >
                    <a
                      href={RELEASE_NOTE_URL}
                      target="_blank"
                      rel="noreferrer"
                      className=" hover:underline"
                    >
                      {REACT_APP_API_VERSION}
                    </a>
                  </Tippy>
                  <br className="hidden md:block" /> Beta
                </p>
              </div>
            </TippyCustomzie>
          </div>
        </div>
      </div>

      {showLogout.value ? (
        <LogoutCard handleChangeButton={handleChangeButton} />
      ) : null}
    </div>
  )

  return isMobile ? (
    <div className="bg-new-black-222222">
      <TippyLayout
        type="content-screen"
        visible={showMenuBar.value}
        mainContent={renderMainContent()}
      >
        <div className="flex items-center justify-between min-h-[52px] px-6">
          <div className="w-full flex ">
            <TraceliumLogo onClick={() => pushTo(PATH.projects)} />
          </div>

          <div
            className="cursor-pointer h-[52px] w-[52px] flex items-center justify-end"
            onClick={() => {
              showMenuBar.setValue(!showMenuBar.value)
            }}
          >
            {showMenuBar.value ? <CloseIcon /> : <MenuBar />}
          </div>
        </div>
      </TippyLayout>
    </div>
  ) : (
    renderMainContent()
  )
}
export default MenuLeft
