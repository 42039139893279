/* eslint-disable react-hooks/exhaustive-deps */
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { checkPermissionPage } from "helpers/utils"
import { dataEditorDefault } from "pages/project-component-detail/types"
import { useContext, useEffect } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  deleteProjectBuildCreateExtraInfoMiddleware,
  postProjectBuildCreateExtraInfoMiddleware,
} from "../api.services"
import ShowInfoAdditionalCard from "../molecules/ShowInfoAdditionalCard"
import { ProjectBuildExtraDetail } from "../project-build.type"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import FormInputEditorCard from "../molecules/FormInputEditorCard"
import { useAdditionalAndExtraInfoProjectBuild } from "../project-build.hook"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"

interface Props {
  extras: ProjectBuildExtraDetail[]
  idProjectBuild: string
  updateWhenCreateExtra: () => void
  updateWhenDeleteExtra: (idAdditional: string) => void
  viewOnly?: boolean
}
const ExtraInfoCard = (props: Props) => {
  const {
    extras,
    idProjectBuild,
    updateWhenCreateExtra,
    updateWhenDeleteExtra,
    viewOnly,
  } = props
  const { archiveProject, archiveBuild, viewOnlyShare } = useContext(
    PermissionProjectBuildPage
  )
  const {
    dataEditor,
    isLoading,
    isScroll,
    keyInputFile,
    ref,
    listFileAdd,
    setDataEditor,
    fileSelectedHandler,
    handleChangeEditor,
    handleDeleteFileNoDownload,
    setListFileAdd,
    handleChangeScroll,
    clearStorageWhenSubmit,
  } = useAdditionalAndExtraInfoProjectBuild("extra_info_", idProjectBuild)
  const { workerUploadFile } = useUploadFileWorkerController()

  useEffect(() => {
    handleChangeScroll()
  }, [JSON.stringify(extras)])

  const onSubmitButton = (dataEditor: string) => {
    if (!idProjectBuild || !workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }
    configureStore.dispatch(startLoading())

    isLoading.setValue(true)
    let newDataEditor = dataEditor
    listFileAdd
      .filter((item) => item.type === "inline")
      .forEach((item) => {
        newDataEditor = newDataEditor.replace(item.url || "", item.id)
      })
    postProjectBuildCreateExtraInfoMiddleware(
      workerUploadFile,
      idProjectBuild,
      {
        description: newDataEditor,
        files: listFileAdd.map((file) => {
          return {
            file: file.file,
            file_name: file.name,
            key: file.id,
            type: file.type,
            file_type: file.file_type,
          }
        }),
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateWhenCreateExtra()
          setListFileAdd([])
          setDataEditor("")
          isScroll.setValue(true)
          clearStorageWhenSubmit()
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }
  const onDeleteComment = (oldItem: ProjectBuildExtraDetail) => {
    if (!oldItem.id) {
      return
    }
    isLoading.setValue(true)
    deleteProjectBuildCreateExtraInfoMiddleware(
      oldItem.id,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          updateWhenDeleteExtra(oldItem.id)
        }
      }
    )
  }
  const onDisableButtonSubmit = () => {
    if (dataEditor !== dataEditorDefault || listFileAdd.length) {
      return false
    }
    return true
  }
  return (
    <div className="flex justify-between flex-col h-full overflow-auto">
      <FormLabelWarning
        className="mb-3 mr-6"
        label="This information would NOT be shared with invitees."
        minHeight={38}
      />
      <div className="flex flex-col flex-1 h-full overflow-auto" ref={ref}>
        {extras.length
          ? extras.map((additional, index) => (
              <div className="pr-0 md:pr-6 mb-3 last:mb-0" key={index}>
                <ShowInfoAdditionalCard
                  isView={checkPermissionPage({
                    project: archiveProject,
                    build: archiveBuild,
                    viewShare: viewOnlyShare,
                  })}
                  item={additional}
                  onDeleteComment={onDeleteComment}
                  isDeleted={viewOnly}
                />
              </div>
            ))
          : null}
      </div>
      {!checkPermissionPage({
        project: archiveProject,
        build: archiveBuild,
        viewShare: viewOnlyShare,
      }) &&
        !viewOnly && (
          <FormInputEditorCard
            handleChangeEditor={handleChangeEditor}
            dataEditor={dataEditor}
            fileSelectedHandler={fileSelectedHandler}
            listFileAdd={listFileAdd}
            keyInputFile={keyInputFile.value}
            handleDeleteFileNoDownload={handleDeleteFileNoDownload}
            onSubmitButton={onSubmitButton}
            onDisableButtonSubmit={onDisableButtonSubmit()}
          />
        )}
    </div>
  )
}
export default ExtraInfoCard
