import {
  isEmpty,
  cloneDeep,
  slice,
  trim,
  isString,
  isArray,
  map,
  merge,
} from "lodash"
import {
  ComponentType,
  TableColumnBOMDetail,
} from "pages/project-component-detail/types"
import { excelService } from "./excel"
import { saveAs } from "file-saver"
import { encryptionController } from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"

const removeQuote = (text: string) => {
  let result = ""
  // re-assign text
  result += trim(text)
  // remove first double quote & single qoute
  if (result && (result.indexOf(`"`) === 0 || result.indexOf(`'`) === 0)) {
    result = result.substring(1)
  }
  // remove last double quote & single qoute
  const valueLength = result.length - 1
  if (
    result &&
    (result.indexOf(`"`) === valueLength || result.indexOf(`'`) === valueLength)
  ) {
    result = result.substring(0, valueLength)
  }
  // console.log("result", result);
  /// remove carriage return
  return result.replace(/\r/g, "")
}

export const convertCopiedCellToJSON = (copiedCell: string) => {
  const breakPoint = "-=TRACELIUM-BREAK=-"
  // re-assign text
  let copyOfCopiedCell = ""
  copyOfCopiedCell += copiedCell
  //
  const regexLinefeed = /(")([\w\d\s$&+,:;=?@#|'<>.^*()%!-]+)(")/g
  const result: any = []

  // find value has linefeed
  const linefeedTexts = copyOfCopiedCell.match(regexLinefeed)
  // replace linefeedText \n with breakPoint
  linefeedTexts?.forEach((text) => {
    if (text !== "\t") {
      copyOfCopiedCell = copyOfCopiedCell.replace(
        text,
        text.replaceAll(/\n/g, breakPoint)
      )
    }
  })

  // transform cell to row data
  const rowData = copyOfCopiedCell.split("\n")
  if (!rowData || rowData.length === 0) {
    return false
  }

  // mapping and return JSON data
  rowData.forEach((item) => {
    const cellValues = item.split("\t")
    /// double check length value of cell
    if (cellValues.length > 0) {
      const convertedData: string[] = []
      cellValues.forEach((cellValue) => {
        // assign right value and replace breakPoint to LINE FEED character
        const transformText = cellValue.replaceAll(
          new RegExp(breakPoint, "g"),
          "\n"
        )
        // remove first double quote & single qoute
        convertedData.push(removeQuote(transformText))
      })
      result.push(convertedData)
    }
  })

  return result
}

export const handlePasteFromCell = (
  extraJson: TableColumnBOMDetail[],
  defaultBomValues: TableColumnBOMDetail["values"],
  copiedData: string,
  type: "header" | "body",
  yIndex: number,
  xIndex: number,
  event: any
) => {
  // handle parse copied data to JSON
  const pastedData = convertCopiedCellToJSON(copiedData)
  if (pastedData === false || isEmpty(pastedData)) {
    return false
  }
  if (pastedData.length === 1 && pastedData[0].length === 1) {
    return false
  }
  /// stop other event input changes
  event.stopPropagation()
  event.preventDefault()

  // copy the original JSON data
  const newData = cloneDeep(extraJson)
  const firstRowData = pastedData.shift()

  // if row data length greater than extraJson row then create new columns
  if (firstRowData.length > newData.length - yIndex) {
    for (let k = newData.length - yIndex; k < firstRowData.length; k++) {
      newData.push({
        idColumn: "",
        key: "",
        values: defaultBomValues,
      })
    }
  }

  // pasted to BOM headers
  if (type === "header") {
    for (let i = yIndex; i < newData.length; i++) {
      newData[i].key = firstRowData[i - yIndex]
    }
  }

  if (type === "body") {
    pastedData.unshift(firstRowData)
  }
  // pasted to BOM body
  for (let j = yIndex; j < newData.length; j++) {
    const bodyData = slice(pastedData, 0, newData[j].values.length)
    /// continute paste to body
    bodyData.forEach((row: any, rowIndex: number) => {
      const newValues = cloneDeep(newData[j].values)
      newValues[xIndex + rowIndex] = row[j - yIndex]
      newData[j].values = newValues
    })
  }

  return newData
}

const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number)

export const combineData = (data: object[], extraData: object[]) => {
  if (!extraData) {
    extraData = []
  }
  return data.map((item, index) => {
    let keys = Object.keys(item)
    const extraKeys = Object.keys(extraData[index] || {})
    extraKeys.forEach((key) => {
      let i = 0
      let temp = key
      while (keys.includes(temp)) {
        i++
        temp = key + "(" + i + ")"
      }
      keys.push(temp)
      if (isNumeric(temp)) {
        item[`${temp} `] = extraData[index][key]
      } else {
        item[temp] = extraData[index][key]
      }
    })
    return item
  })
}

const columnDataToJson = (data: { key: string; values: string[] }[]) => {
  return data.reduce((pre: any, cur) => {
    return cur.values.map((item, index) => {
      let temp: any = pre[index] || {}
      temp = {
        ...temp,
        [cur.key]: item,
      }
      return temp
    })
  }, [])
}
const toOriginKeysColumnData = (data: { key: string; values: string[] }[]) => {
  const keys: string[] = []
  return data.map((item) => {
    const origin = item.key?.slice(0, -9)
    let i = 0
    let temp = origin
    while (keys.includes(temp)) {
      i++
      temp = origin + "(" + i + ")"
    }
    keys.push(temp)
    return {
      ...item,
      key: temp,
    }
  })
}

export const exportBom = async (
  fileName: string,
  bomJson: any,
  additionalJson: any,
  mouserData: any,
  inviteeBoms: any,
  type: ComponentType
) => {
  // Combine additionalJson
  let temp = combineData(
    cloneDeep(bomJson),
    columnDataToJson(toOriginKeysColumnData(cloneDeep(additionalJson)))
  )
  // Combine Invitee AdditionalJson
  if (inviteeBoms && isArray(inviteeBoms)) {
    inviteeBoms.forEach((inviteeBom: any) => {
      temp = combineData(
        temp,
        columnDataToJson(
          toOriginKeysColumnData(cloneDeep(inviteeBom.additional_json))
        )
      )
    })
  }
  // Combine Mouser Data
  temp = map(temp, (item, i: number) => {
    if (!isArray(mouserData)) {
      return merge(item, mouserData)
    }
    return merge(item, mouserData[i])
  })
  // Export to excel
  temp = excelService.addIndexColumn(temp)
  const data = await excelService.toExcel(temp)
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  })
  saveAs(blob, fileName)
}

export const handleAdditionalJson = async (
  additionalJson: any,
  isEncryption = false,
  decryptedShareKey?: string
) => {
  if (!additionalJson || !isArray(additionalJson)) {
    return []
  }
  return Promise.all(
    additionalJson.map(async (item: any) => {
      let decryptedValues = []
      if (isEncryption) {
        decryptedValues = JSON.parse(
          ((await encryptionController().decrypt(
            item.values,
            decryptedShareKey
              ? {
                  dataType: "string",
                  encryptionKey: decryptedShareKey,
                  type: "component-shared",
                }
              : {
                  dataType: "string",
                  type: "project",
                }
          )) as string) || "{}"
        )
      } else {
        try {
          decryptedValues = item.values
          if (isString(item.values)) {
            decryptedValues = JSON.parse(item.values)
          }
        } catch {}
      }
      return {
        ...item,
        values: decryptedValues,
      }
    })
  )
}

export const handleBomResponse = async (
  response: any,
  decryptedShareKey?: string
) => {
  let jsonFromBOM = response.data.data.bom_json || []
  let additionalJson = response.data.data.additional_json || []
  let inviteeJsons = response.data.data.invitee_boms || []
  let mouserData = response.data.data.mouser_data
  const isEncryption = encryptionController().currentProjectEncryptionKey
    ? true
    : false

  if (isEncryption || decryptedShareKey) {
    const data = await encryptionController().decrypt(
      response.data.data.raw_bom_data,
      decryptedShareKey
        ? {
            dataType: "file",
            type: "component-shared",
            encryptionKey: decryptedShareKey,
          }
        : {
            dataType: "file",
            type: "project",
          }
    )
    const dataToDownload = encryptionHelper.toUint8Array(data)
    //
    jsonFromBOM = excelService
      .readBufferFile(dataToDownload)
      .getFirstSheetJSON()
    mouserData = mouserData
      ? await encryptionController().decrypt(
          mouserData,
          decryptedShareKey
            ? {
                dataType: "string",
                type: "component-shared",
                encryptionKey: decryptedShareKey,
              }
            : {
                dataType: "string",
                type: "project",
              }
        )
      : []
  }
  //
  return {
    ...response.data.data,
    bom_json: jsonFromBOM,
    mouser_data:
      mouserData && isString(mouserData)
        ? JSON.parse(mouserData)
        : mouserData || [],
    additional_json: await handleAdditionalJson(
      additionalJson,
      decryptedShareKey ? true : isEncryption,
      decryptedShareKey
    ),
    invitee_boms: await Promise.all(
      inviteeJsons.map(async (inviteeJson: any) => {
        return {
          ...inviteeJson,
          additional_json: await handleAdditionalJson(
            inviteeJson.additional_json,
            isEncryption
          ),
        }
      })
    ),
  }
}

export const ManufacturerPosiblePartName = [
  "manufacturer part number",
  "manufacturer part no",
  "part number",
  "part no",
  "manufacturerpartno",
  "manufacturerpartnumber",
]
export const ManufacturerPosibleQtyName = ["qty", "quantity"]

export const findQtyCol = (bomItem: any) => {
  if (!bomItem) {
    return 1
  }
  const bomObjectColumns = Object.keys(bomItem)
  const qtyCol =
    bomObjectColumns.find((key) =>
      ManufacturerPosibleQtyName.includes(key.toLowerCase())
    ) || ""
  return parseInt(bomItem[qtyCol] || "1")
}

export const getSuplierPartAndQty = (bomJSON: any) => {
  const colName = Object.keys(bomJSON[0])
  const partCol =
    colName.find((key) =>
      ManufacturerPosiblePartName.includes(key.toLowerCase())
    ) || ""
  const qtyCol =
    colName.find((key) =>
      ManufacturerPosibleQtyName.includes(key.toLowerCase())
    ) || ""
  if (!partCol) {
    return false
  }
  //
  const parts = bomJSON.map((item: any) => {
    return {
      part: item[partCol] as string,
      qty: parseInt(item[qtyCol] || "1"), // default qty is 1
    }
  }) as { part: string; qty: number }[]
  return parts
}
