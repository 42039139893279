import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { useBoolean, useDebounce, useString } from "helpers/hooks"
import { customLocalStorageHandler, b64toBlob } from "helpers/utils"
import { cloneDeep, remove, uniqueId } from "lodash"
import { ConversationsPageContext } from "pages/conversations/contexts/ConversationsPage.context"
import { FileEditorDetail } from "pages/project-component-detail/types"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import {
  Stores,
  deleteData,
  getStoreData,
  updateData,
} from "services/indexedDB"
import { v4 as uuid } from "uuid"

export const useAdditionalAndExtraInfoProjectBuild = (
  newKeyInputFile: string,
  relationId?: string
) => {
  // tab_conversation_detail , additional_info  ,extra_info_,conversation
  const [dataEditor, setDataEditor] = useState<string>("")

  const keyInputFile = useString()
  const [listFileAdd, setListFileAdd] = useState<FileEditorDetail[]>([])
  const isLoading = useBoolean(true)
  const ref = useRef<HTMLDivElement>(null)
  const isScroll = useBoolean(false)
  const { updateCurrentConvoInConvoList } = useContext(ConversationsPageContext)
  let { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    newKeyInputFile as NAME_LOCALSTORAGE
  )
  const getStoreType = (): Stores => {
    if (
      newKeyInputFile === "tab_conversation_detail" ||
      newKeyInputFile === "conversation"
    ) {
      return Stores.Conversations
    }
    if (newKeyInputFile === "additional_info") {
      return Stores.AdditionalInfo
    }
    return Stores.ExtraInfo
  }
  useEffect(() => {
    getDefaultDraft()
  }, [relationId])
  const getDefaultDraft = async () => {
    const data = await getStoreData<{
      id: string
      listFile: FileEditorDetail[]
      text: string
    }>(getStoreType())
    const draftData = data.find((item) => item.id === relationId)
    let input = draftData?.text || ""
    const draftFiles = draftData?.listFile
    draftFiles?.forEach((item, index) => {
      if (item.type !== "inline") return
      const blob = b64toBlob(item.file)
      if (!blob) return
      const imageUrl = URL.createObjectURL(blob)
      input = input.replace(item.url || "", imageUrl)
      draftFiles[index].url = imageUrl
    })
    setDataEditor(input)
    setListFileAdd(draftData?.listFile || [])

    if (draftFiles?.some((item) => item.type === "inline")) {
      await updateData(getStoreType(), {
        text: input,
        id: relationId,
        listFile: draftFiles,
      })
    }
  }
  const handleDeleteFileNoDownload = (oldIndex: number) => async () => {
    const newListFile = cloneDeep(listFileAdd)
    remove(newListFile, (_el, index) => index === oldIndex)
    setListFileAdd(newListFile)
    if (relationId) {
      try {
        const storeType = getStoreType()
        if (storeType === Stores.Conversations) {
          updateCurrentConvoInConvoList(relationId, {
            draftAttachments: newListFile.length,
          })
        }
        await updateData(storeType, {
          listFile: newListFile,
          id: relationId,
        })
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err.message)
        } else {
          console.log("Something went wrong")
        }
      }
    }
  }
  const handleChangeEditor = async (description: string) => {
    if (relationId) {
      debouceSaveToIndexDb(description)
    }
  }
  const savetoIndexDb = async (description: string) => {
    try {
      if (getStoreType() === Stores.Conversations && relationId) {
        updateCurrentConvoInConvoList(relationId || "", {
          draftMessage: description === "<p><br></p>" ? "" : description,
        })
      }
      setDataEditor(description)
      await updateData(getStoreType(), {
        text: description,
        id: relationId,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const debouceSaveToIndexDb = useDebounce(savetoIndexDb, 200, [relationId])
  const clearStorageWhenSubmit = () => {
    if (relationId) {
      deleteData(getStoreType(), relationId)
    }
  }

  const fileSelectedHandler = async (
    files: FileList,
    type?: "inline" | "outline",
    imgUrl?: string,
    fileInLine?: FileList,
    fileInLineUrl?: { name: string; imageUrl: string }[]
  ) => {
    const currentFiles: any = []
    const currentFileInLine: any = []
    let totalFileSize = 0
    for (const [, file] of Object.entries(files)) {
      totalFileSize += file.size
      currentFiles.push(file)
    }
    if (fileInLine?.length) {
      for (const [, fileLine] of Object.entries(fileInLine)) {
        totalFileSize += fileLine.size
        currentFileInLine.push(fileLine)
      }
    }
    if (totalFileSize > 20 * 1000 * 1000) {
      return toast(
        <LabelNotificationPage
          messenger={`The upload of file larger than 20MB is not allowed`}
          type="error"
        />
      )
    }
    const fileRequest: FileEditorDetail[] = []
    const calls: Promise<any>[] = []
    currentFiles.forEach((element) => {
      fileRequest.push({
        file: element,
        file_type: element.type,
        name: element.name,
        url: imgUrl,
        id: uuid(),
        type: type === "inline" ? type : "outline",
      })
    })
    if (currentFileInLine.length) {
      currentFileInLine.forEach((elementInLine) => {
        const newURL = fileInLineUrl?.find(
          (el) => el.name === elementInLine.name
        )
        fileRequest.push({
          file: elementInLine,
          file_type: elementInLine.type,
          name: elementInLine.name,
          url: newURL?.imageUrl || "",
          id: uuid(),
          type: "inline",
        })
      })
    }

    await Promise.all(calls)
    keyInputFile.setValue(uniqueId(`${newKeyInputFile}_`))
    const newListFileAdd = cloneDeep(listFileAdd)
    const newFiles = newListFileAdd.concat(fileRequest)

    setListFileAdd(newFiles)
    if (relationId) {
      try {
        if (getStoreType() === Stores.Conversations) {
          updateCurrentConvoInConvoList(relationId, {
            draftAttachments: newFiles.length,
          })
        }
        const res = await updateData(getStoreType(), {
          listFile: newFiles,
          id: relationId,
        })
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err.message)
        } else {
          console.log("Something went wrong")
        }
      }
    }
  }
  const handleChangeScroll = () => {
    if (ref.current) {
      ref.current.scrollTo(0, ref.current.scrollHeight)
      isScroll.setValue(false)
    }
  }

  return {
    dataEditor,
    keyInputFile,
    isLoading,
    ref,
    isScroll,
    listFileAdd,
    setListFileAdd,
    handleDeleteFileNoDownload,
    handleChangeEditor,
    fileSelectedHandler,
    setDataEditor,
    handleChangeScroll,
    clearStorageWhenSubmit,
  }
}

export const useSelectComponentInProjectBuild = () => {
  const onSelectComponent =
    (
      newIdComponent: string,
      _isValidToBuild: boolean,
      idProjectBuildComponent: string,
      typeKey: string
    ) =>
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      switch (typeKey) {
        case TYPE_PROJECT_COMPONENT.PCB:
          return window.open(
            `/project/build-component/${newIdComponent}/PCB/${idProjectBuildComponent}`
          )
        // return pushTo(PATH.projectComponentPCB, {
        //   idProjectComponent: newIdComponent,
        //   titlePage: "build-component",
        //   idProjectBuildComponent,
        // })
        case TYPE_PROJECT_COMPONENT.BOM:
          return window.open(
            `/project/build-component/${newIdComponent}/BOM/${idProjectBuildComponent}`
          )
        // return pushTo(PATH.projectComponentBOM, {
        //   idProjectComponent: newIdComponent,
        //   titlePage: "build-component",
        //   idProjectBuildComponent,
        // })
        default:
          return window.open(
            `/project/build-component/${newIdComponent}/${typeKey}/${idProjectBuildComponent}`
          )
        // return pushTo(PATH.projectComponentOther, {
        //   idProjectComponent: newIdComponent,
        //   type: typeKey,
        //   titlePage: "build-component",
        //   idProjectBuildComponent,
        // })
      }
    }
  return {
    onSelectComponent,
  }
}
