import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
} from "pages/project-component/types"
import {
  FileUrlProp,
  BuildHistoryDetail,
  ExtendedViewType,
  ComponentType,
  defaultBuildHistoryDetail,
} from "pages/project-component-detail/types"
import {
  getHistoriesBuildComponentConvoMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectComponentHistoryMiddleware,
  getProjectComponentOtherMiddleware,
} from "pages/project-component-detail/services"
import AttachmentOtherCard from "pages/project-component-detail/organisms/AttachmentOtherCard"
import {
  findHistoryTreeDefault,
  handleCheckUpdateFileComponent,
  downloadPrivateAssetForInvitee,
} from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import {
  getConversationMessagesMiddleware,
  getConversationRoleMiddleWare,
} from "pages/conversations/conversations.api"
import { useInviteeComponent } from "./invitees-component.hook"
import { getProjectDetailMiddleware } from "pages/projects/services/api"
import PageInviteeComponent from "./PageInviteeComponent"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { SpecificationHeader } from "components/specification/SpecificationHeader"
import { Mech3DModel } from "components/ThreeDModels/Mech3DModel"
import { useMechComponent } from "pages/project-component-detail/project-component-detail.hook"
import FileInviteeItem from "./FileInviteePCBItem"
import { STATUS_TYPE_FILE_BUILD } from "pages/project-component-detail/build-history.constant"
import CustomCollapse from "pages/project-component-detail/molecules/CustomCollapse"
import HistoryTreeCard from "components/HistoryTree/HistoryTreeCard"
import { configureStore } from "stores/configureStore"
import { closeModal, openModal } from "reducers/modal"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import {
  deleteInvitee3DFile,
  deleteInviteeSubBomFile,
  postInviteeTabContentMiddleware,
  uploadInvitee3DFile,
  uploadInviteeSubBomFile,
} from "./services/invitee.api"
import { cloneDeep, debounce, includes, remove } from "lodash"
import { CONVERSATION_ROLE } from "pages/conversations/conversations.type"
import InviteeBOMDetailCard from "./InviteeBOMDetailCard"
import InputEditorDefault2 from "components/Input/InputEditorDefault2"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { startLoading } from "reducers/loading"

const InviteeMechComponent = () => {
  const params = useParams<{
    idProjectComponent: string
    idProjectBuildComponent: string
    idProjectComponentHistory: string
    idConversation: string
  }>()
  const locationInviteeComponentOther = useLocation<any>()
  const { workerUploadFile } = useUploadFileWorkerController()

  const userInfo = useAppSelector((state) => state.userInfo)
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [dataEditor, setDataEditor] = useState<string>("")
  const updatedAtProjectComponent = useString("")
  const [listFileUrl, setListFileUrl] = useState<FileUrlProp[]>([])
  const versionHistory = useString("")
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const maxFile = 50
  const isUpdatingBom = useBoolean()
  const {
    conversationMessages,
    idConversationParam,
    idProjectBuildComponentParam,
    idProjectComponentHistoryParam,
    idProjectComponentParam,
    isReadComponent,
    nameBOM,
    projectBuild,
    projectDetail,
    setProjectDetail,
    setConversationMessages,
    setProjectBuild,
    idComponentHistory,
    conversationRole,
    setConversationRole,
  } = useInviteeComponent(params)

  const {
    detailViewType,
    setDetailViewType,
    mech3DFile,
    setMech3DFile,
    bomDetail,
    getSubBomDetail,
  } = useMechComponent()

  const componentType = "mechanical"
  const viewOnly =
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED, STATUS_BUILD.SAVE],
      historyDetail.status
    ) || conversationRole !== CONVERSATION_ROLE.INVITEE
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail?.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  useEffect(() => {
    const isCallAPI =
      !idProjectComponentParam ||
      !idProjectBuildComponentParam ||
      !idConversationParam

    if (isCallAPI) {
      pushTo(PATH.conversations)
      return
    }
    getProjectComponent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponentParam,
    idProjectBuildComponentParam,
    idConversationParam,
  ])

  const getProjectComponent = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponentParam,
        idConversationParam
      )
      setComponentDetail(dataComponentRes)
      await getConversationRole()
      // if (idComponentHistory.value) {
      //   await getDataInType(idProjectComponentHistoryParam, componentType)
      //   await getComponentHistory(idProjectComponentHistoryParam)
      // } else {
      await getHistories(componentType)
      // }

      await getConversationMessages(idConversationParam)
      await getProjectBuildComponent(idProjectBuildComponentParam)
      await getIsReadProjectBuildComponent(idProjectBuildComponentParam)
      updatedAtProjectComponent.setValue(dataComponentRes.updated_at)
      nameBOM.setValue(dataComponentRes.name.replace(" ", "_"))

      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.conversations)
    }
  }
  const getConversationRole = async () => {
    const role = await getConversationRoleMiddleWare(
      idProjectBuildComponentParam,
      idConversationParam
    )
    setConversationRole(role.role)
  }
  const getHistories = async (
    oldType: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getHistoriesBuildComponentConvoMiddleware(
        idProjectBuildComponentParam,
        idConversationParam
      )
      setHistories(dataRes)
      if (dataRes.length) {
        await saveDataHistoryDetail(dataRes, oldType, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const saveDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    oldType: string,
    findItemCommit = true
  ) => {
    const { newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationInviteeComponentOther
    )
    if (newHistoryDetail.code) {
      versionHistory.setValue(newHistoryDetail.code)
    }
    idComponentHistory.setValue(newHistoryDetail.id)
    setHistoryDetail(newHistoryDetail)
    await getDataInType(
      newHistoryDetail.id,
      oldType,
      newHistoryDetail.decryptedShareKey || ""
    )
  }
  const getComponentHistory = async (originIdHistory: string) => {
    const dataComponentHistoryRes = await getProjectComponentHistoryMiddleware(
      originIdHistory,
      idConversationParam
    )
    if (dataComponentHistoryRes.code) {
      versionHistory.setValue(dataComponentHistoryRes.code)
    }
    setHistoryDetail(dataComponentHistoryRes)
  }

  const getProjectBuildComponent = async (idProjectBuildComponent: string) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      idProjectBuildComponent,
      idConversationParam
    )
    setProjectBuild(dataRes)
    if (
      locationInviteeComponentOther.state &&
      Boolean(locationInviteeComponentOther?.state?.breadcrumpProject)
    ) {
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataRes.project_id,
        idConversationParam
      )
      setProjectDetail(dataProjectDetailRes)
    }
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent,
      idConversationParam
    )
    isReadComponent.setValue(dataRes.is_read_invitee_comment)
  }

  const getDataInType = useCallback(
    async (
      oldIdHistory: string,
      oldType: string,
      decryptedShareKey: string,
      loading = false
    ) => {
      try {
        const dataRes = await getProjectComponentOtherMiddleware(
          oldIdHistory,
          oldType.toLocaleLowerCase(),
          idConversationParam,
          decryptedShareKey
        )
        // load bom detail
        await getSubBomDetail(
          oldIdHistory,
          componentType,
          idConversationParam,
          decryptedShareKey
        )
        setDataEditor(dataRes.description || "")

        setListFileUrl(dataRes.files)
        setMech3DFile({
          three_d_file: dataRes.three_d_file || "",
          three_d_file_name: dataRes.three_d_file_name || "",
          blobURI: dataRes.three_d_file
            ? ((await downloadPrivateAssetForInvitee(
                dataRes.three_d_file,
                {
                  access_token: localStorage.getItem("access_token") || "",
                  conversation_id: idConversationParam,
                },
                undefined,
                true,
                decryptedShareKey
              )) as string)
            : "",
        })
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        if (loading) {
          setDataEditor("")
          setListFileUrl([])
          isLoading.setValue(false)
        }
      }
    },
    [isLoading]
  )
  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getDataInType(
        newHistoryDetail.id,
        componentType,
        historyDetail.decryptedShareKey || "",
        true
      )
      isUpdatingBom.setValue(false)
    },
    [idComponentHistory.value, isLoading]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )
  const getConversationMessages = async (
    conversationId: string,
    loading = false
  ) => {
    const dataRes = await getConversationMessagesMiddleware(conversationId)
    setConversationMessages(dataRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }
  const handleUpdateDataWhenAddFile = async (
    fileRequest: FormData,
    fileErrors: File[]
  ) => {
    if (!idComponentHistory.value || !workerUploadFile) {
      return
    }
    isLoading.setValue(true)
    configureStore.dispatch(startLoading())
    fileErrors.forEach((fileError) => {
      toast(
        <LabelNotificationPage
          messenger={`File ${fileError.name} wrong format! Files allowed: rar,zip,7z`}
          type="warning"
        />
      )
    })
    await postInviteeTabContentMiddleware(
      workerUploadFile,
      idComponentHistory.value,
      idConversationParam,
      componentDetail.type.key.toLocaleLowerCase(),
      fileRequest,
      async (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          await handleUpdateDataTypeSuccessWhenCallAPI()

          return
        }
        handleUpdateDataTypeErrorWhenCallAPI(error)
      }
    )
  }
  const handleUpdateDataWhenDeleteFile = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
  }
  const handleUpdateDataTypeSuccessWhenCallAPI = async () => {
    await getDataInType(
      idComponentHistory.value,
      componentDetail.type.key.toLocaleLowerCase(),
      historyDetail.decryptedShareKey || "",
      true
    )
    toast(
      <LabelNotificationPage
        messenger={`Update ${componentDetail.type.key} successfully!`}
        type={STATUS_RESPONSE.SUCCESS}
      />
    )
    // updatedAtComponentDetail(new Date().toISOString())
  }
  const deleteFile = (onSubmit: () => void) => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `Are you sure to delete this file?`,
            label: `Confirmation`,
            onSubmit,
            titleButton: `Delete`,
          },
        },
      })
    )
  }
  const handleUpdateFileBom = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory.value,
        Object(newFile.get("file"))?.name,
        "csv,xls,xlsx",
        "csv|xls|xlsx"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)

    uploadInviteeSubBomFile(
      workerUploadFile,
      idComponentHistory.value,
      idConversationParam,
      componentType,
      newFile,
      (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS}
              type={STATUS_RESPONSE.SUCCESS}
            />
          )
          getDataInType(
            idComponentHistory.value,
            componentType,
            historyDetail.decryptedShareKey || ""
          ).then(() => {
            setDetailViewType(ExtendedViewType.Bom)
          })

          return
        }
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type={STATUS_RESPONSE.ERROR}
          />
        )
      }
    )
  }

  const removeBomFile = () => {
    deleteInviteeSubBomFile(
      idComponentHistory.value,
      idConversationParam,
      componentType
    )
      .then(() => {
        getDataInType(
          idComponentHistory.value,
          componentType,
          historyDetail.decryptedShareKey || ""
        )
          .then(() => {
            setDetailViewType(ExtendedViewType.HideAll)
            closeModal()
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS}
                type={STATUS_RESPONSE.SUCCESS}
              />
            )
          })
          .catch((_error) => {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR}
                type={STATUS_RESPONSE.ERROR}
              />
            )
          })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }
  const uploadMech3DFile = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory.value,
        Object(newFile.get("file")).name,
        ".stl, .obj, .3ds, .stp, .step",
        "stl|stp|obj|3ds|step"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)
    uploadInvitee3DFile(
      workerUploadFile,
      idComponentHistory.value,
      idConversationParam,
      componentType,
      newFile,
      (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.UPLOAD_3D_FILE_SUCCESS}
              type={STATUS_RESPONSE.SUCCESS}
            />
          )
          getDataInType(
            idComponentHistory.value,
            componentType,
            historyDetail.decryptedShareKey || ""
          ).then(() => {
            setDetailViewType(ExtendedViewType.Mech3D)
          })

          return
        }
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type={STATUS_RESPONSE.ERROR}
          />
        )
      }
    )
  }
  const removeMech3DFile = () => {
    deleteInvitee3DFile(
      idComponentHistory.value,
      idConversationParam,
      componentType,
      mech3DFile.three_d_file_name
    )
      .then(() => {
        getDataInType(
          idComponentHistory.value,
          componentType,
          historyDetail.decryptedShareKey || ""
        )
          .then(() => {
            setDetailViewType(ExtendedViewType.HideAll)
            closeModal()
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_3D_FILE_SUCCESS}
                type={STATUS_RESPONSE.SUCCESS}
              />
            )
          })
          .catch((_error) => {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_3D_FILE_ERROR}
                type={STATUS_RESPONSE.ERROR}
              />
            )
          })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }
  const handleUpdateDataTypeErrorWhenCallAPI = async (error: any) => {
    let message = error?.response?.data?.message
    if (error.response.status === 413 && !message) {
      message = `The upload of file larger than ${maxFile}MB is not allowed`
    }
    toast(
      <LabelNotificationPage
        messenger={message || `Update ${componentDetail.type.key} failed!`}
        type={STATUS_RESPONSE.ERROR}
      />
    )
    isLoading.setValue(false)
  }
  const onSubmitAdditional = async (description: string, type: string) => {
    if (
      !historyDetail.id ||
      historyDetail.status !== "Draft" ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)
    const formData = new FormData()
    formData.append("description", description)
    await postInviteeTabContentMiddleware(
      workerUploadFile,
      historyDetail.id,
      idConversationParam,
      type,
      formData,
      (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          return
        }
        handleUpdateDataTypeErrorWhenCallAPI(error)
      }
    )
  }
  const debouceOnSubmitAdditionalMessage = useCallback(
    debounce(onSubmitAdditional, 500),
    [historyDetail.id]
  )
  const handleUpdateDataWhenChangeHistory = (getFirst = false) => {
    isLoading.setValue(true)
    getHistories(componentType, true, getFirst)
  }
  const updateDataWhenChangeColumn = async (callback: () => void) => {
    isLoading.setValue(true)
    await getSubBomDetail(
      historyDetail.id,
      componentType,
      idConversationParam,
      historyDetail.decryptedShareKey
    )
    updatedAtProjectComponent.setValue(new Date().toISOString())
    callback()
  }
  return (
    <PageInviteeComponent
      componentDetail={componentDetail}
      updatedAtProjectComponent={updatedAtProjectComponent.value}
      projectBuild={projectBuild}
      idConversationParam={idConversationParam}
      idProjectBuildComponentParam={idProjectBuildComponentParam}
      conversationMessages={conversationMessages}
      projectDetail={projectDetail}
      idProjectComponentHistoryParam={historyDetail.id}
      nameBOM={nameBOM.value}
      userInfo={userInfo}
      isReadComponent={isReadComponent}
      isLoading={isLoading.value}
      histories={histories}
      versionHistory={historyDetail}
      setHistoryDetail={setHistoryDetail}
      isDeleted={isDeleted}
      handleUpdateDataWhenChangeHistory={handleUpdateDataWhenChangeHistory}
      commitButtonStage={{
        isActive:
          (listFileUrl.length > 0 || mech3DFile.three_d_file) &&
          historyDetail.status === STATUS_BUILD.DRAFT
            ? true
            : false,
        tooltipHelper: `Please upload ${componentDetail.type.key.toLocaleLowerCase()} attachment or 3D file`,
      }}
      conversationRole={conversationRole}
    >
      <div className="flex flex-col pl-6 py-6 grow overflow-hidden">
        <div className="overflow-auto h-full">
          {/* {histories.length !== 0 && (
            <HistoryTreeCard
              histories={histories}
              historyDetail={historyDetail}
              handleChangeHistoryDetail={handleChangeHistoryDetail}
              typeComponent={componentDetail.type.key}
              titlePage={"build-component"}
            />
          )} */}
          <div className="mt-1 overflow-x-auto pb-2 md:pb-0 mr-6 md:mr-0">
            <div className="grid grid-cols-box-2-min-324 md:grid-cols-auto-4 mr-6 gap-2">
              <FileInviteeItem
                title={STATUS_TYPE_FILE_BUILD.MECH_3D}
                file={mech3DFile.three_d_file}
                fileName={mech3DFile.three_d_file_name}
                acceptFile=".stl, .obj, .3ds, .stp, .step"
                detailViewType={detailViewType}
                setDetailViewType={setDetailViewType}
                isDeleted={isDeleted}
                conversationId={idConversationParam}
                onRemoveFile={() => deleteFile(removeMech3DFile)}
                handleUpdateFile={uploadMech3DFile}
                isAction={viewOnly}
                containerFiles={`container-files-mech-3d`}
                decryptedShareKey={historyDetail.decryptedShareKey || ""}
              />
              <FileInviteeItem
                title={STATUS_TYPE_FILE_BUILD.BOM}
                file={bomDetail?.file || ""}
                fileName={bomDetail?.file_name || ""}
                acceptFile=".csv,.xls,.xlsx"
                detailViewType={detailViewType}
                setDetailViewType={setDetailViewType}
                // downloadUri={`${API_URL}/api/${componentType}/${params.idProjectComponentHistory}/${params.idConversation}/export-bom`}
                isDeleted={isDeleted}
                conversationId={idConversationParam}
                handleUpdateFile={handleUpdateFileBom}
                onRemoveFile={() => deleteFile(removeBomFile)}
                isAction={viewOnly}
                containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
                decryptedShareKey={historyDetail.decryptedShareKey || ""}
              />
            </div>
          </div>

          <div className="mt-6">
            <AttachmentOtherCard
              listFileUrl={listFileUrl}
              viewOnly={viewOnly}
              typeOther={componentType}
              styleRoot={{
                marginTop: 0,
              }}
              isDeleted={isDeleted}
              conversationId={params.idConversation}
              handleUpdateDataWhenAddFile={handleUpdateDataWhenAddFile}
              handleUpdateDataWhenDeleteFile={handleUpdateDataWhenDeleteFile}
              decryptedShareKey={historyDetail.decryptedShareKey || ""}
            />
          </div>

          <div className="flex flex-col mr-6">
            {detailViewType === ExtendedViewType.Mech3D ? (
              <div className="mt-6">
                <CustomCollapse
                  changeHeaderBorder={false}
                  isToggle={true}
                  buttonClass="border-0"
                  header={<SpecificationHeader title="3D file viewer" />}
                  childrenBody={
                    <div className="py-3 mr-6">
                      {mech3DFile.blobURI ? (
                        <Mech3DModel
                          url={mech3DFile.blobURI}
                          fileName={mech3DFile.three_d_file}
                        />
                      ) : null}
                    </div>
                  }
                />
              </div>
            ) : null}
          </div>
          <div
            className={`flex flex-col ${
              !bomDetail || detailViewType !== ExtendedViewType.Bom
                ? "hidden"
                : ""
            }`}
          >
            <InviteeBOMDetailCard
              BOMJson={bomDetail?.bom_json || []}
              projectComponentHistory={historyDetail}
              updatedAtBOM={bomDetail?.updated_at || ""}
              isInvitee={true}
              isCloseConversation={false}
              updateDataWhenChangeColumn={updateDataWhenChangeColumn}
              nameBOM={bomDetail?.file_name || "N/A"}
              additionalJson={bomDetail?.additional_json || []}
              inviteeBoms={[]}
              componentType={ComponentType.Mechanical}
              isAddColumn={isUpdatingBom}
              conversationId={idConversationParam}
              conversationRole={conversationRole}
            />
          </div>
          <div
            className="flex flex-col mr-6 mt-6 p-3"
            style={{
              border: "1px solid #E4E4E4",
              borderRadius: 4,
              background: "#FFFFFF",
            }}
          >
            <p
              className="font-semibold mb-2"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
              }}
            >
              Additional info
            </p>
            <InputEditorDefault2
              readOnly={viewOnly}
              setValue={(description) => {
                if (description !== dataEditor) {
                  debouceOnSubmitAdditionalMessage(
                    description,
                    componentDetail.type.key.toLocaleLowerCase()
                  )
                }
              }}
              value={dataEditor}
              versionId={historyDetail.id}
            />
          </div>
        </div>
      </div>
    </PageInviteeComponent>
  )
}

export default InviteeMechComponent
