import Breadcrumbs from "components/Breadcrumbs"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import { PATH } from "constants/path"
import { ProjectComponentDetail } from "pages/project-component/types"
import React from "react"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { pushTo } from "helpers/history"
import HeaderBreadcrumbCard from "components/Breadcrumbs/HeaderBreadcrumbCard"
import IDInfoConversationCard from "./IDInfoConversationCard"
import { useWindowSize } from "helpers/hooks"
import { ProjectDetail } from "pages/projects/types"
import { useAppSelector } from "hooks/useApp"
import { CONVERSATION_ROLE } from "pages/conversations/conversations.type"

interface Props {
  projectComponentDetail: ProjectComponentDetail
  updatedAtProjectComponent: string
  projectBuild: ProjectComponentBuildDetail
  projectDetail: ProjectDetail
  idConversation: string
  idProjectBuildComponentParam: string
  convoID: string
  conversationRole: CONVERSATION_ROLE
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.projectComponentDetail === nextProps.projectComponentDetail &&
    prevProps.updatedAtProjectComponent ===
      nextProps.updatedAtProjectComponent &&
    prevProps.projectBuild === nextProps.projectBuild &&
    prevProps.idConversation === nextProps.idConversation &&
    prevProps.idProjectBuildComponentParam ===
      nextProps.idProjectBuildComponentParam &&
    prevProps.convoID === nextProps.convoID &&
    prevProps.projectDetail === nextProps.projectDetail
  )
}
const HeaderInviteeComponent = React.memo((props: Props) => {
  const {
    projectComponentDetail,
    updatedAtProjectComponent,
    projectBuild,
    idConversation,
    idProjectBuildComponentParam,
    convoID,
    projectDetail,
    conversationRole,
  } = props
  const sizeWindow = useWindowSize()
  const user = useAppSelector((state) => state.userInfo)
  const onCallbackBuildPage = () => {
    pushTo(
      PATH.buildOverview,
      {
        status: projectBuild.status,
        idProject: projectBuild.project_id,
        idProjectBuild: projectBuild.id,
      },
      undefined,
      {
        tab: projectBuild.created_by === user.id ? "my-builds" : "others",
      }
    )
  }

  const listConversationBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: "Conversations",
      path: PATH.conversations,
    },
    // {
    //   name: projectBuild.name,
    //   onCustomRouter: onCallbackConversationDetailPage,
    // },
    {
      name: projectComponentDetail.name,
    },
  ]
  const listProjectBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectDetail.name,
      field: "idProject",
      path: PATH.projectComponent,
      value: projectDetail.id,
    },
    {
      name: projectBuild.name,
      onCustomRouter: onCallbackBuildPage,
    },
    {
      name: projectComponentDetail.name,
    },
  ]
  if (!projectBuild.id) {
    return null
  }
  const breadcrumbs = projectDetail.id
    ? listProjectBreadcrumbs
    : listConversationBreadcrumbs
  return (
    <div className="flex justify-between items-center h-full px-6">
      {sizeWindow.width < 1600 ? (
        <HeaderBreadcrumbCard
          renderBreadcrumb={<Breadcrumbs listBreadcrumbs={breadcrumbs} />}
          renderIDInfo={
            <IDInfoConversationCard
              projectBuild={projectBuild}
              projectComponentDetail={projectComponentDetail}
              updatedAtProjectComponent={updatedAtProjectComponent}
              idProjectBuildComponentParam={idProjectBuildComponentParam}
              convoID={convoID}
              idConversation={idConversation}
              idProject={projectDetail.id}
              conversationRole={conversationRole}
            />
          }
        />
      ) : (
        <React.Fragment>
          <Breadcrumbs listBreadcrumbs={breadcrumbs} overflow />
          <IDInfoConversationCard
            projectBuild={projectBuild}
            projectComponentDetail={projectComponentDetail}
            updatedAtProjectComponent={updatedAtProjectComponent}
            idProjectBuildComponentParam={idProjectBuildComponentParam}
            convoID={convoID}
            idConversation={idConversation}
            idProject={projectDetail.id}
            conversationRole={conversationRole}
          />
        </React.Fragment>
      )}
    </div>
  )
}, isEqualProps)

export default HeaderInviteeComponent
