import { useBoolean, useString } from "helpers/hooks"
import { useEffect, useRef, useState } from "react"
import {
  BuildHistoryDetail,
  DataErrorWhenEditHistoryTree,
  emptyDataErrorWhenEditHistoryTree,
  emptyInfoFileDetail,
  InfoFileDetail,
  LayerAsignmentDetail,
  LayerRender,
  ParamUserWorkingItem,
  PCBSpecificationSelection,
  TAB_URL_PROJECT_COMPONENT,
  UserWorkingItem,
  ExtendedViewType,
  BOMDetail,
  SelectCurrencyDetail,
  FileUrlProp,
} from "./types"

import { ProjectComponentDetail } from "pages/project-component/types"
import {
  checkPermissionPage,
  downloadFile,
  downloadEncrypted,
} from "helpers/utils"
import { STATUS_BUILD } from "components/Status/types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { API_URL, REACT_APP_API_CURRENCIES } from "config/environments"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "hooks/useApp"
import {
  getListUserWorkingMiddleware,
  getBOMCurrenciesMiddleware,
  getComponentSubBOMMiddleware,
} from "./services"
import { EncryptionKeys } from "controllers/EncryptionController"
import { isEmpty } from "lodash"

export const useActionHistoryTree = (
  projectComponentDetail: ProjectComponentDetail,
  historyDetail: BuildHistoryDetail,
  titlePage: string,
  viewOnlyShare: boolean,
  archive: {
    project: boolean
    component: boolean
    build: boolean
  },
  disableButton: {
    specificationRequired: string[]
    isDisableEditHistory: boolean
    isDisableTranscendHistory: boolean
    isDisableRevertHistory: boolean
    isDisableMergeHistory: boolean
    isUpdatingBom: boolean
  }
) => {
  const isCommit = useBoolean()
  const isCreateAndCopyHistory = useBoolean(false)
  const isRevert = useBoolean(false)
  const isTranscend = useBoolean(false)
  const isWarningTranscend = useBoolean(false)
  const isLoading = useBoolean(false)
  const isMerge = useBoolean(false)
  const showTippyComment = useBoolean()
  const isEditNameComponent = useBoolean(false)
  const newNameComponent = useString("")
  const isShowModalErrorEditHistory = useBoolean(false)
  const [errorEditTree, setErrorEditTree] =
    useState<DataErrorWhenEditHistoryTree>(emptyDataErrorWhenEditHistoryTree)
  const refInput = useRef<any>(null)
  const isDeleteComponent = useBoolean(false)
  const location = useLocation()
  const userInfo = useAppSelector((state) => state.userInfo)
  let timeOutID = 0
  const [listUserWorkings, setListUserWorking] = useState<UserWorkingItem[]>([])
  const isWarningCommitUserWorking = useBoolean(false)
  useEffect(() => {
    if (!userInfo.id || !projectComponentDetail.id) {
      return
    }
    setListUserWorking([])
    getListUserWorking()
    return () => {
      if (timeOutID) {
        clearTimeout(timeOutID)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.id, projectComponentDetail.id])

  const getListUserWorking = async () => {
    const params: ParamUserWorkingItem = {
      user_id: userInfo.id,
      url: location.pathname,
    }
    getListUserWorkingMiddleware(params, (dataRes: UserWorkingItem[]) => {
      setListUserWorking(dataRes.filter((el) => el.id !== userInfo.id))
      timeOutID = Number(
        setTimeout(() => {
          getListUserWorking()
        }, 30000)
      )
    })
  }

  useEffect(() => {
    newNameComponent.setValue(projectComponentDetail.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectComponentDetail])

  const openModalCommit = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        notificationBuild: true,
      }) ||
      historyDetail.status !== STATUS_BUILD.DRAFT
    ) {
      return
    }

    if (disableButton.specificationRequired.length) {
      toast(
        <LabelNotificationPage
          messenger={`PCB Specification Selection:${disableButton.specificationRequired.join(
            ", "
          )} is required!`}
          type="warning"
        />
      )
      return
    }
    if (disableButton.isUpdatingBom) {
      toast(
        <LabelNotificationPage
          messenger={`Please update BOM data before committing!`}
          type="warning"
        />
      )
      return
    }
    if (listUserWorkings.length) {
      isWarningCommitUserWorking.setValue(true)
      return
    }

    isCommit.setValue(true)
  }
  const openModalCreate = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        notificationBuild: true,
      }) ||
      disableButton.isDisableEditHistory ||
      disableAllButton()
    ) {
      return
    }
    isCreateAndCopyHistory.setValue(true)
  }
  const openModalRevert = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        notificationBuild: true,
      }) ||
      disableButton.isDisableRevertHistory ||
      disableAllButton()
    ) {
      return
    }
    isRevert.setValue(true)
  }

  const openModalTranscend = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        notificationBuild: true,
      }) ||
      disableButton.isDisableTranscendHistory ||
      disableAllButton()
    ) {
      return
    }
    isWarningTranscend.setValue(true)
  }
  const openModalMerge = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        notificationBuild: true,
      }) ||
      disableButton.isDisableMergeHistory
    ) {
      return
    }
    if (historyDetail.status === STATUS_BUILD.MERGED) {
      return toast(
        <LabelNotificationPage
          messenger={`This version has been merged already!`}
          type="warning"
        />
      )
    }

    isMerge.setValue(true)
  }

  const disableAllButton = () => {
    if (
      titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT &&
      historyDetail.project_build_component_id
    ) {
      return true
    }
    return false
  }

  const onCloseModalErrorEditHistory = () => {
    isShowModalErrorEditHistory.setValue(false)
    setErrorEditTree(emptyDataErrorWhenEditHistoryTree)
  }

  const onSubmitError = () => {
    isShowModalErrorEditHistory.setValue(false)
    switch (errorEditTree.type) {
      case TYPE_PROJECT_COMPONENT.PCB:
        return pushTo(PATH.projectComponentPCB, {
          idProjectComponent: errorEditTree.project_component_id,
          titlePage:
            titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
              ? TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              : TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent:
            titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              ? "history"
              : errorEditTree.project_build_component_id,
        })
      case TYPE_PROJECT_COMPONENT.BOM:
        return pushTo(PATH.projectComponentBOM, {
          idProjectComponent: errorEditTree.project_component_id,
          titlePage:
            titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
              ? TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              : TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent:
            titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              ? "history"
              : errorEditTree.project_build_component_id,
        })
      default:
        return pushTo(PATH.projectComponentOther, {
          idProjectComponent: errorEditTree.project_component_id,
          type: errorEditTree.type,
          titlePage:
            titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
              ? TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              : TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent:
            titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT
              ? "history"
              : errorEditTree.project_build_component_id,
        })
    }
  }
  const openFormErrorWhenEditHistoryTree = (
    newData: DataErrorWhenEditHistoryTree
  ) => {
    isShowModalErrorEditHistory.setValue(true)
    setErrorEditTree(newData)
  }
  const onDownloadFile = () => {
    if (!historyDetail.id) {
      return
    }
    isLoading.setValue(true)
    /* if (isEmpty(currentProjectEncryptionKey)) {
      downloadFile(
        `${API_URL}/api/project-component/history/${historyDetail.id}/download`,
        historyDetail.code ?? new Date().toISOString(),
        true
      )
    } else {
      downloadEncrypted(
        `/api/project-component/history/${historyDetail.id}/files`
      )
    } */
    downloadEncrypted(
      `/api/project-component/history/${historyDetail.id}/files`
    )
    isLoading.setValue(false)
  }
  const handleDeleteComponent = () => {
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
        project: archive.project,
        component: archive.component,
        build: archive.build,
        notificationProject: true,
        notificationViewShare: true,
        notificationBuild: true,
        notificationComponent: true,
      })
    ) {
      return
    }
    if (historyDetail.status !== STATUS_BUILD.DRAFT) {
      return
    }
    isDeleteComponent.setValue(true)
  }

  return {
    isCommit,
    isCreateAndCopyHistory,
    isRevert,
    isTranscend,
    isWarningTranscend,
    isLoading,
    isMerge,
    showTippyComment,
    isEditNameComponent,
    newNameComponent,
    isShowModalErrorEditHistory,
    errorEditTree,
    refInput,
    isDeleteComponent,
    listUserWorkings,
    isWarningCommitUserWorking,
    setErrorEditTree,
    openModalRevert,
    openModalCreate,
    openModalCommit,
    openModalTranscend,
    openModalMerge,
    disableAllButton,
    onSubmitError,
    onCloseModalErrorEditHistory,
    openFormErrorWhenEditHistoryTree,
    onDownloadFile,
    handleDeleteComponent,
  }
}

export const useGerberPCBComponent = () => {
  const [layersPCB, setLayersPCB] = useState<LayerRender[]>([])
  const [layerAssignment, setLayerAssignment] = useState<
    LayerAsignmentDetail[]
  >([])
  const isToggleGerber = useBoolean(false)
  const isToggleSpecification = useBoolean(false)
  const idComponentHistory = useString()
  const canViewGerber = useBoolean(true)
  const [infoFileGerber, setInfoFileGerber] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [infoFileBOM, setInfoFileBOM] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [infoFileStackup, setInfoFileStackup] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [infoFileAssembly, setInfoFileAssembly] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [specifications, setSpecifications] = useState<
    PCBSpecificationSelection[]
  >([])
  const [listFileUrl, setListFileUrl] = useState<FileUrlProp[]>([])

  return {
    layersPCB,
    layerAssignment,
    isToggleGerber,
    isToggleSpecification,
    idComponentHistory,
    infoFileGerber,
    infoFileBOM,
    infoFileStackup,
    infoFileAssembly,
    specifications,
    setLayersPCB,
    setLayerAssignment,
    setInfoFileGerber,
    setInfoFileBOM,
    setInfoFileStackup,
    setInfoFileAssembly,
    setSpecifications,
    canViewGerber,
    listFileUrl,
    setListFileUrl,
  }
}

export const useMechComponent = () => {
  const [detailViewType, setDetailViewType] = useState<ExtendedViewType>(
    ExtendedViewType.HideAll
  )
  const default3DData = {
    three_d_file: "",
    three_d_file_name: "",
    blobURI: "",
  }
  const [mech3DFile, setMech3DFile] = useState(default3DData)
  const [bomDetail, setBomDetail] = useState<BOMDetail>()
  const [currencies, setCurrencies] = useState<SelectCurrencyDetail[]>([])
  const isUpdatingBom = useBoolean(false)

  const getBOMCurrencies = async () => {
    await getBOMCurrenciesMiddleware(
      REACT_APP_API_CURRENCIES ? JSON.parse(REACT_APP_API_CURRENCIES) : []
    ).then((data) => {
      setCurrencies(data)
    })
  }

  const getSubBomDetail = async (
    idHistory: string,
    type: string,
    conversationId?: string,
    decryptedShareKey?: string
  ) => {
    isUpdatingBom.setValue(false)
    await getComponentSubBOMMiddleware(
      idHistory,
      type,
      conversationId,
      decryptedShareKey
    )
      .then((res) => setBomDetail(res))
      .catch((err) => {
        console.log("err", err)
        setBomDetail(undefined)
      })
  }
  const resetMech3DFile = () => {
    setMech3DFile(default3DData)
  }

  return {
    detailViewType,
    setDetailViewType,
    mech3DFile,
    setMech3DFile,
    bomDetail,
    setBomDetail,
    isUpdatingBom,
    getBOMCurrencies,
    currencies,
    getSubBomDetail,
    resetMech3DFile,
  }
}
