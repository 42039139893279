import IconLoading from "assets/images/loading-history.gif"
import { MessageBackupType } from "./type"
interface Props {
  messageBackups: { content: string; type: MessageBackupType }[]
  currentBackup: MessageBackupType
  errorMessageBackup: boolean
}

export const FormBackup = (props: Props) => {
  const { messageBackups, currentBackup, errorMessageBackup } = props

  const renderLabel = (currenIndex: number) => {
    let label = "Pending"
    let bg = "rgba(10, 90, 245, 0.15)"
    let color = "#0A5AF5"
    const index = messageBackups.findIndex(
      (item) => item.type === currentBackup
    )
    if (index === currenIndex) {
      label = "Processing"
      bg = "rgba(253, 190, 68, 0.15)"
      color = "#F7AC1B"
    }
    if (index > currenIndex || index === messageBackups.length - 1) {
      label = errorMessageBackup ? "Error" : "Done"
      bg = errorMessageBackup
        ? "rgba(234, 69, 69, 0.15)"
        : "rgba(86, 160, 18, 0.15)"
      color = errorMessageBackup ? "#EA4545" : "#56A012"
    }

    return (
      <div
        className="py-[4px] px-[8px] rounded-[20px]"
        style={{
          backgroundColor: bg,
        }}
      >
        <p
          className="text-[11px] font-semibold leading-[14px]"
          style={{
            color: color,
          }}
        >
          {label}
        </p>
      </div>
    )
  }
  return (
    <div
      className="inset-0 flex fixed items-center justify-center"
      style={{
        zIndex: 10000,
        backgroundColor: "#22222285",
      }}
    >
      <div
        className="p-3 bg-white rounded-md flex flex-col"
        style={{
          width: 400,
        }}
      >
        <div className="flex flex-col relative">
          <div
            className="flex justify-center"
            style={{
              height: 216,
            }}
          >
            <img
              src={IconLoading}
              alt="icon"
              style={{
                width: 216,
              }}
            />
          </div>
          <div className="flex flex-col">
            {messageBackups.map((messageBackup, index) => {
              const isActive = messageBackup.type === currentBackup

              return (
                <div
                  className="flex justify-between items-center mb-[8px]"
                  key={index}
                >
                  <p
                    className="font-normal"
                    style={{
                      fontSize: 12,
                      lineHeight: "24px",
                      color: isActive ? "#222222" : "#7A7A7A",
                      fontWeight: isActive ? "bold" : "normal",
                    }}
                  >
                    {messageBackup.content}
                  </p>
                  {renderLabel(index)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
