import Tippy from "@tippyjs/react"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import {
  formatDateForConversationMessage,
  downloadPrivateAsset,
} from "helpers/utils"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import ActionItem from "pages/projects/molecules/ActionItem"
import { ProjectBuildAdditionalDetail } from "../project-build.type"
import "./ShowInfoAdditionalCard.css"
import { useEffect } from "react"

interface Props {
  item: ProjectBuildAdditionalDetail
  onDeleteComment: (oldItem: ProjectBuildAdditionalDetail) => void
  isView?: boolean
  isDeleted?: boolean
  styleRootCard?: React.CSSProperties
  conversationId?: string
  idProjectBuild?: string
}
const ShowInfoAdditionalCard = (props: Props) => {
  const {
    item,
    isView = false,
    styleRootCard,
    onDeleteComment,
    isDeleted,
    conversationId,
    idProjectBuild,
  } = props
  const showTippy = useBoolean(false)

  useEffect(() => {
    const indentedElements = document.querySelectorAll("[class^='ql-indent-']")

    indentedElements.forEach((el) => {
      const indentLevel = el.className.match(/ql-indent-(\d+)/)
      if (indentLevel) el.setAttribute("data-indent", indentLevel[1])
    })
  }, [item.description])

  useEffect(() => {
    const indentedElements = document.querySelectorAll("[data-indent]")

    indentedElements.forEach((el: any) => {
      const indentLevel = el.getAttribute("data-indent")
      if (indentLevel) {
        const baseIndent = 40
        el.style.marginLeft = `${baseIndent * indentLevel}px`
      }
    })
  }, [item.description])
  return (
    <div
      className="p-2 flex flex-col cursor-pointer relative"
      style={{
        backgroundColor: "#F7F7F7",
        borderRadius: 6,
        paddingRight: 45,
        ...styleRootCard,
      }}
    >
      <div
        style={{
          fontWeight: 400,
          fontSize: 13,
          lineHeight: "22px",
          color: "#111111",
        }}
        className="break-words list-order"
        dangerouslySetInnerHTML={{
          __html: item.description,
        }}
      ></div>
      {item.files && item.files.length ? (
        <div className="flex items-center mt-2">
          {item.files.map((file, index) => (
            <a
              key={index}
              onClick={
                !isDeleted
                  ? () =>
                      downloadPrivateAsset(
                        file.file,
                        conversationId
                          ? {
                              conversation_id: conversationId,
                              project_build_id: idProjectBuild,
                            }
                          : idProjectBuild
                          ? { project_build_id: idProjectBuild }
                          : {}
                      )
                  : undefined
              }
            >
              <Tippy
                className="custom-tippy-menu-left"
                placement="top"
                content={
                  <span
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      wordBreak: "break-word",
                    }}
                  >
                    {file.file_name}
                  </span>
                }
              >
                <div
                  className="flex items-center h-7 px-2 mr-2 cursor-pointer flex-wrap"
                  style={{
                    background: "#F1F1F1",
                    border: "1px solid #E4E4E4",
                    borderRadius: 4,
                    maxWidth: 200,
                  }}
                >
                  <p
                    className="mr-1"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "18px",
                      color: "#111111",
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file.file_name}
                  </p>
                </div>
              </Tippy>
            </a>
          ))}
        </div>
      ) : null}
      <div className="flex items-center mt-2">
        <p
          className="mr-1"
          style={{
            fontWeight: 400,
            fontSize: 13,
            lineHeight: "22px",
            color: "#7A7A7A",
          }}
        >
          Last update:
        </p>
        <p
          className="mr-1"
          style={{
            fontWeight: 400,
            fontSize: 13,
            lineHeight: "22px",
            color: "#333333",
          }}
        >
          {`${
            item?.created_at
              ? `${formatDateForConversationMessage(item?.created_at)}`
              : "No update"
          } by ${item.user.name}`}
        </p>
      </div>
      {!isDeleted && !isView ? (
        <TippyCustomzie
          containerClass="hidden md:component-card-menu"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 120,
              }}
            >
              <ActionItem
                title="Delete"
                onClick={() => {
                  showTippy.setValue(false)
                  onDeleteComment(item)
                }}
              />
            </div>
          }
          allowHTML
        >
          <div
            onClick={() => showTippy.setValue(!showTippy.value)}
            className="absolute"
            style={{
              right: 5,
              top: 5,
            }}
          >
            <ActionIconHistory
              icon="three-dots"
              tooltip="Action"
              styleIcon={{
                marginRight: 0,
              }}
            />
          </div>
        </TippyCustomzie>
      ) : null}
    </div>
  )
}
export default ShowInfoAdditionalCard
