let request: IDBOpenDBRequest
let db: IDBDatabase
let version = 2
export enum Stores {
  Conversations = "conversations",
  AdditionalInfo = "addtional_infos",
  ExtraInfo = "extra_infos",
  PrivateKeys = "private_keys",
}

export const initDB = (): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    request = indexedDB.open("tracelium", version)

    request.onupgradeneeded = () => {
      db = request.result

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(Stores.Conversations)) {
        db.createObjectStore(Stores.Conversations, { keyPath: "id" })
      }
      if (!db.objectStoreNames.contains(Stores.AdditionalInfo)) {
        db.createObjectStore(Stores.AdditionalInfo, { keyPath: "id" })
      }
      if (!db.objectStoreNames.contains(Stores.ExtraInfo)) {
        db.createObjectStore(Stores.ExtraInfo, { keyPath: "id" })
      }
      if (!db.objectStoreNames.contains(Stores.PrivateKeys)) {
        db.createObjectStore(Stores.PrivateKeys, { keyPath: "id" })
      }
      // no need to resolve here
    }

    request.onsuccess = function () {
      db = this.result
      version = db.version
      console.log("request.onsuccess - initDB", version)
      resolve(true)
    }

    request.onerror = () => {
      resolve(false)
    }
  })
}

export const addData = <T>(
  storeName: string,
  data: T
): Promise<T | string | null> => {
  return new Promise((resolve) => {
    request = indexedDB.open("tracelium", version)

    request.onsuccess = function () {
      db = this.result
      const tx = db.transaction(storeName, "readwrite")
      const store = tx.objectStore(storeName)
      store.add(data)
      resolve(data)
    }

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error)
      } else {
        resolve("Unknown error")
      }
    }
  })
}
export const updateData = <T>(
  storeName: string,
  data: any
): Promise<T | string | null> => {
  return new Promise((resolve) => {
    request = indexedDB.open("tracelium", version)

    request.onsuccess = function () {
      db = this.result
      const tx = db.transaction(storeName, "readwrite")
      const store = tx.objectStore(storeName)

      const res = store.get(data.id)
      let originalData
      res.onsuccess = () => {
        originalData = res.result
        store.put({ ...originalData, ...data })
      }
    }

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error)
      } else {
        resolve("Unknown error")
      }
    }
  })
}
export const getStoreData = <T>(storeName: Stores): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB.open("tracelium")

    request.onsuccess = function () {
      console.log("request.onsuccess - getAllData")
      db = this.result
      const tx = db.transaction(storeName, "readonly")
      const store = tx.objectStore(storeName)
      const res = store.getAll()
      res.onsuccess = () => {
        resolve(res.result)
      }
    }
  })
}
export const deleteData = (
  storeName: string,
  key: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    // again open the connection
    request = indexedDB.open("tracelium", version)

    request.onsuccess = function () {
      db = this.result
      const tx = db.transaction(storeName, "readwrite")
      const store = tx.objectStore(storeName)
      const res = store.delete(key)

      // add listeners that will resolve the Promise
      res.onsuccess = () => {
        resolve(true)
      }
      res.onerror = () => {
        resolve(false)
      }
    }
  })
}
