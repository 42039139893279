export enum MessageBackupType {
  RETRIEVE_BACKUP_VERSION,
  DECRYPTING_BACKUP_VERSION,
  ENCRYPTING_BACKUP_VERSION,
  CREATING_BACKUP_FILE_VERSION,
  CREATING_BACKUP_CONTEXT_VERSION,
  DONE_BACKUP_VERSION,
  RETRIEVE_BACKUP_MESSAGE,
  DECRYPTING_BACKUP_MESSAGE,
  ENCRYPTING_BACKUP_MESSAGE,
  CREATING_BACKUP_MESSAGE,
  DONE_BACKUP_MESSAGE,
  ERROR_BACKUP,
}
