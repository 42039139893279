import {
  enableEncryptionMiddleware,
  settingTwoFactor,
  verifySettingMFA,
} from "../services/api"
import { useAppSelector } from "hooks/useApp"
import Button from "components/Button/Button"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { EnableEncryptionRequest, MFAMethod, UserInfo } from "../types"
import { useBoolean, useString } from "helpers/hooks"
import YesNoModal from "../molecules/ConfirmationModal"
import Typography from "components/Typography"
import { ReactComponent as EncryptionLockIcon } from "assets/images/icons/enable-encryption-icon.svg"
import ModalCustom from "components/ModalCustom"
import FormInputCard from "../molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import {
  EncryptionKeys,
  encryptionController,
} from "controllers/EncryptionController"
import { setUserInfoAction } from "../stores/actions"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useState } from "react"
import { isEmpty } from "lodash"
import { STATUS_RESPONSE } from "types"
import { ReactComponent as IconExport } from "assets/images/icons/export.svg"
import { encryptionHelper } from "helpers/encryption"
import { Stores, updateData } from "services/indexedDB"
import { endLoading, startLoading } from "reducers/loading"

interface TwoFactorFormProps {
  handleSubmit: (type: "enable" | "disable", method: MFAMethod) => void
  userInfo: UserInfo
}
interface EnableDataEncryptionModalProps {
  handleEnableDataEncryption: (password: string) => void
  onCloseModal: () => void
}
const EnableTwoFactorForm = (props: TwoFactorFormProps) => {
  const { handleSubmit, userInfo } = props
  const onDisableTwoFactor = (method: MFAMethod) => () => {
    handleSubmit("disable", method)
    closeModal()
  }
  const openWarningModal = (method: MFAMethod) => {
    const methodText =
      method === MFAMethod.email ? "email" : "authenticator app"
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `This reduces the security level of your account and is not recommended.`,
            label: `Disable 2FA by ${methodText}`,
            content: `Are you sure you want to continue?`,
            onSubmit: onDisableTwoFactor(method),
            titleButton: `Yes, Disable 2FA`,
            styleTitle: {
              textAlign: "center",
            },
          },
        },
      })
    )
  }

  const onSwitchMFAByAuthenticatorApp = () => {
    if (userInfo.mfaMethods?.includes(MFAMethod.authenticatorApp)) {
      openWarningModal(MFAMethod.authenticatorApp)
    } else {
      handleSubmit("enable", MFAMethod.authenticatorApp)
    }
  }

  return (
    <div>
      <p className="text-sm text-black font-semibold mb-1">
        Two-factor authentication
      </p>
      <p className="text-brown">
        Protect your account with two-factor authentication (2FA). During login,
        you'll need a code in addition to your email and password to verify your
        identity.
      </p>
      <div className="mt-2 mb-8 border border-[#E4E4E4] rounded-[6px]">
        <div className="flex flex-col pb-[10px] pt-[10px] pl-[12px] pr-[12px] border-b border-[#E4E4E4]">
          <div className="flex items-center justify-between">
            <p className="text-sm text-black font-semibold">Email OTP</p>
            <div className="pt-[3px] pb-[3px] pl-[8px] pr-[8px] bg-green_opacity_015 rounded-[20px]">
              <p className="text-[#56A012] text-[12px] font-semibold">
                Enabled
              </p>
            </div>
          </div>
          <p className="text-brown mt-[4px]">
            By default, you'll receive authentication codes via email for
            account security and verification.
          </p>
        </div>
        <div className="flex flex-col pb-[10px] pt-[10px] pl-[12px] pr-[12px]">
          <div className="flex items-center justify-between">
            <p className="text-sm text-black font-semibold">
              Google Authenticator
            </p>
            <SwitchButtonDefault
              checked={userInfo.mfaMethods?.includes(
                MFAMethod.authenticatorApp
              )}
              onChange={onSwitchMFAByAuthenticatorApp}
            />
          </div>
          <p className="text-brown mt-[4px] mb-[4px]">
            Use Google Authenticator to generate secure authentication codes
            directly from your device, providing an extra layer of protection
            for your account.
          </p>
          <div className="flex items-center">
            <div className="flex items-center mr-[24px]">
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
                className="flex items-center text-[14px] text-[#0A5AF5] font-[400]"
              >
                <IconExport className="mr-[6px]" />
                IOS app
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=vi"
                target="_blank"
                className="flex items-center text-[14px] text-[#0A5AF5] font-[400]"
              >
                <IconExport className="mr-[6px]" />
                Android app
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EnableDataEncryptionModal = (props: EnableDataEncryptionModalProps) => {
  const password = useString()
  const { handleEnableDataEncryption, onCloseModal } = props
  return (
    <ModalCustom
      renderHeader={
        <div className="flex gap-2 items-center">
          <EncryptionLockIcon />
          <Typography
            className="text-base font-semibold"
            style={{
              color: "#222222",
              lineHeight: "26px",
            }}
          >
            Enable data encryption
          </Typography>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              With data encryption, all future projects you create will be
              encrypted for enhanced security. Once enabled, data encryption
              cannot be disabled.
            </p>
            <p
              style={{
                color: "#222222",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Your password is required to enable data encryption.
            </p>
            <FormInputCard title="Password" required customClass="w-full">
              <InputDefault
                type="password"
                isPassword
                value={password.value}
                onChange={(e) => password.setValue(e.target.value)}
              />
            </FormInputCard>
          </div>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
            <Button
              widthBtn={"100%"}
              title="Close"
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              widthBtn={"100%"}
              title={"Enable"}
              colorBtn="yellow"
              disabled={!password.value}
              onClick={() => handleEnableDataEncryption(password.value)}
            />
          </div>
        </div>
      }
    />
  )
}

const DataEncryptionForm = ({ forceEncryption = false }: any) => {
  const {
    generateMasterKeyAndPasswordHashed,
    generateVaultKeyAndBackUpKeyAndEncryptedVersion,
    storeEncryptionKey,
    generateNoteKeyAndEncryptedVersion,
  } = encryptionController()
  const userInfo = useAppSelector((state) => state.userInfo)
  const backUpKeyState = useString()
  const openRecoveryModal = useBoolean()
  const openConfirmationModal = useBoolean()
  const openDataEnableEncryptionModal = useBoolean()
  const handleEnableDataEncryption = async (password: string) => {
    configureStore.dispatch(startLoading())
    setTimeout(async () => {
      const { masterKey, passwordHash } =
        await generateMasterKeyAndPasswordHashed(password, userInfo.email)
      const { privateKey, publicKey } = await encryptionHelper.generateKeyPair()

      if (!privateKey || !publicKey) {
        toast(
          <LabelNotificationPage
            type="error"
            messenger={"Generate public and private failed!"}
          />
        )
        configureStore.dispatch(endLoading())
        return
      }

      const {
        vaultKey,
        backupKey,
        encryptedBackUpkey,
        encryptedKMSBackupKey,
        encryptedVaultkey,
      } = await generateVaultKeyAndBackUpKeyAndEncryptedVersion(masterKey)
      // Create conversation note encryption key
      const { conversationNoteKey, encryptedConversationNoteKey } =
        generateNoteKeyAndEncryptedVersion(vaultKey)

      const encryptedPrivateKey = encryptionHelper.encrypt(
        masterKey,
        privateKey
      ) as string

      const enableEncryptionRequest: EnableEncryptionRequest = {
        password,
        passwordHash,
        encryptedVaultkey,
        encryptedBackUpkey,
        encryptedConversationNoteKey,
        encryptedKMSBackupKey,
        encryptedPrivateKey,
        publicKey,
      }
      enableEncryptionMiddleware(enableEncryptionRequest)
        .then(async (res) => {
          backUpKeyState.setValue(backupKey)
          storeEncryptionKey(EncryptionKeys.userMasterKey, masterKey)
          storeEncryptionKey(EncryptionKeys.userVaultKey, vaultKey)
          storeEncryptionKey(
            EncryptionKeys.conversationNoteEncryptionKey,
            conversationNoteKey
          )
          openDataEnableEncryptionModal.setValue(false)
          openRecoveryModal.setValue(true)
          configureStore.dispatch(setUserInfoAction({ is_encrypted: true }))
          localStorage.setItem("enabled_encrypted", "1")
          toast(<LabelNotificationPage type="success" messenger={"Success"} />)

          await updateData(Stores.PrivateKeys, {
            privateKey,
            id: userInfo.id,
          })
        })
        .catch((error) => {
          console.log(error)
          toast(
            <LabelNotificationPage
              type="error"
              messenger={error.response?.data.message}
            />
          )
        })
    }, 200)
  }
  return (
    <div>
      {forceEncryption ? (
        <h4 className="text-sm text-red font-semibold mt-4 mb-4">
          To ensure the highest level of data protection, we require you to
          enable encryption mode before accessing any sensitive information.
          Encryption safeguards your data by scrambling it, making it unreadable
          to unauthorized individuals.
        </h4>
      ) : undefined}
      <div className="flex flex-col">
        <p className="text-sm text-black font-semibold">Data encryption</p>
        <div className="flex flex-col mt-2 border border-[#E4E4E4] rounded-[6px] pb-[10px] pt-[10px] pl-[12px] pr-[12px]">
          <div className="flex items-center justify-between h-[24px]">
            <p className="text-sm text-black font-semibold">Data encryption</p>
            {userInfo.is_encrypted ? (
              <div className="pt-[3px] pb-[3px] pl-[8px] pr-[8px] bg-green_opacity_015 rounded-[20px]">
                <p className="text-[#56A012] text-[12px] font-semibold">
                  Enabled
                </p>
              </div>
            ) : (
              <Button
                colorBtn="white"
                sizeBtn="small"
                title="Enable"
                onClick={() => openDataEnableEncryptionModal.setValue(true)}
              ></Button>
            )}
          </div>

          <p className="mt-1">
            All projects will be encrypted for added security. Once enabled,
            encryption cannot be turned off.
          </p>
        </div>
      </div>

      {openDataEnableEncryptionModal.value && (
        <EnableDataEncryptionModal
          handleEnableDataEncryption={handleEnableDataEncryption}
          onCloseModal={() => openDataEnableEncryptionModal.setValue(false)}
        />
      )}
      {openConfirmationModal.value ? (
        <YesNoModal
          onCancel={() => {
            openConfirmationModal.setValue(false)
          }}
          onConfirm={() => {
            openConfirmationModal.setValue(false)
            openRecoveryModal.setValue(false)
            pushTo(PATH.projectSensitiveData)
          }}
        />
      ) : undefined}
    </div>
  )
}
const TwoFactorSetting = ({ forceEncryption = false }: any) => {
  const userInfo = useAppSelector((state) => state.userInfo)
  const [qrCode, setQRCode] = useState("")
  const mfaCode = useString("")
  const isEnableMFA = useBoolean(false)
  const onUpdateTwoFactor = (type: "enable" | "disable", method: MFAMethod) => {
    if (type === "enable") {
      isEnableMFA.setValue(true)
      umamiTracking(EVENT.ENABLE_2FA)
    }
    settingTwoFactor(type, method).then((res) => {
      if (res.qrCode) {
        setQRCode(res.qrCode)
      }
    })
  }
  const onSubmit = () => {
    if (isEmpty(mfaCode.value)) {
      return toast(
        <LabelNotificationPage
          type="error"
          messenger={"MFA Code is required."}
        />
      )
    }
    verifySettingMFA(
      {
        mfaCode: mfaCode.value,
        isEnable: isEnableMFA.value,
      },
      (typeRes: STATUS_RESPONSE, messenger) => {
        if (typeRes === STATUS_RESPONSE.SUCCESS) {
          setQRCode("")
          mfaCode.setValue("")
        } else {
          toast(
            <LabelNotificationPage messenger={messenger ?? ""} type="error" />
          )
        }
      }
    )
  }
  return (
    <div className="flex flex-col">
      {!forceEncryption ? (
        <EnableTwoFactorForm
          handleSubmit={onUpdateTwoFactor}
          userInfo={userInfo}
        />
      ) : undefined}
      <DataEncryptionForm forceEncryption={forceEncryption} />
      {!isEmpty(qrCode) ? (
        <ModalCustom
          renderHeader={
            <div className="flex gap-2 items-center">
              <Typography
                className="text-base font-semibold"
                style={{
                  color: "#222222",
                  lineHeight: "26px",
                }}
              >
                Scan the QR code
              </Typography>
            </div>
          }
          bodyChildren={
            <div>
              <Typography
                className="text-sm font-normal"
                style={{
                  color: "#222222",
                }}
              >
                Use the Authenticatior app to scan the QR code. This will
                connect the Authenticator app with your Tracelium account.
              </Typography>
              <img
                style={{ width: 200, height: 200, margin: "16px auto" }}
                src={qrCode}
              />
              <Typography
                className="text-sm font-normal"
                style={{
                  color: "#222222",
                  paddingBottom: 16,
                }}
              >
                Then put the verification code from the Authenticator app here.
              </Typography>
              <FormInputCard title="MFA Code">
                <InputDefault
                  type="input"
                  value={mfaCode.value}
                  onChange={(e) => {
                    mfaCode.setValue(e.target.value)
                  }}
                />
              </FormInputCard>
              <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-between">
                <Button
                  widthBtn={"100%"}
                  title="Cancel"
                  colorBtn="white"
                  onClick={() => {
                    setQRCode("")
                    mfaCode.setValue("")
                  }}
                />
                <Button
                  widthBtn={"100%"}
                  title="Submit"
                  colorBtn="yellow"
                  onClick={onSubmit}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  )
}
export default TwoFactorSetting
