import React, { useEffect, useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import {
  PasswordResetRequest,
  defaultPasswordResetRequest,
  MFAMethod,
} from "./types"
import { useArray, useBoolean, useNumber } from "helpers/hooks"
import { pushTo } from "helpers/history"
import { STATUS_RESPONSE } from "types"
import { PATH } from "constants/path"
import {
  checkIsEnabledMFAMiddleware,
  passwordResetMiddleware,
  resendCodeTwoFactor,
} from "./services/api"
import {
  isValidEmail,
  searchParams,
  umamiTracking,
  validationPassword,
} from "helpers/utils"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import Button from "components/Button/Button"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { EVENT } from "constants/events"
import { encryptionHelper } from "helpers/encryption"
import { encryptionController } from "controllers/EncryptionController"
import { cloneDeep, isEmpty } from "lodash"
import { TwoFactorForm } from "../auth/Login"
const PasswordReset = () => {
  const {
    statusInputConfirm,
    statusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  } = useChangeLabelStatusInputPasswordAndConfirm()
  const { generateMasterKeyAndPasswordHashed } = encryptionController()
  const [request, setRequest] = useState<PasswordResetRequest>(
    defaultPasswordResetRequest
  )
  const isLoading = useBoolean()
  const isShowMFAForm = useBoolean(false)
  const mfaMethods = useArray([])
  const currentMFAMethod = useNumber(undefined)
  const newResetPasswordToken = searchParams("token") || ""
  const encryptedBackUpVaultkey = decodeURIComponent(searchParams("key") || "")
  const email = decodeURIComponent(searchParams("email") || "")
  const { executeRecaptcha } = useGoogleReCaptcha()
  useEffect(() => {
    if (!isEmpty(email)) {
      checkIsEnabledMFA(email)
    }
  }, [email])

  const checkIsEnabledMFA = async (email: string) => {
    const isEnabledMFARes = await checkIsEnabledMFAMiddleware(email)
    if (isEnabledMFARes.isEnabled) {
      currentMFAMethod.setValue(
        isEnabledMFARes.methods.includes(MFAMethod.email)
          ? MFAMethod.email
          : isEnabledMFARes.methods[0]
      )
      mfaMethods.setValue(isEnabledMFARes.methods)
    }
  }
  const handleChangeInput =
    (key: "confirmedPassword" | "password" | "mfaCode") => (event) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      })
      if (key === "password") {
        if (request.confirmedPassword) {
          getStatusInputConfirm(event.target.value, request.confirmedPassword)
        }
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedPassword") {
        getStatusInputConfirm(event.target.value, request.password)
      }
    }

  const handleDisableButton = () => {
    if (
      !validationPassword(request.password) ||
      request.password !== request.confirmedPassword
    ) {
      return true
    }
    return false
  }

  const onSubmitResetPassword = async () => {
    const newRequest = cloneDeep(request)

    let vaultKey = ""
    isLoading.setValue(true)
    try {
      if (encryptedBackUpVaultkey) {
        if (!isValidEmail(email)) {
          return toast(
            <LabelNotificationPage messenger={"Invalid email."} type="error" />
          )
        }
        const backupKey = ""
        vaultKey = encryptionHelper.decrypt(
          backupKey,
          encryptedBackUpVaultkey
        ) as string
        if (!vaultKey) {
          return toast(
            <LabelNotificationPage
              messenger={"Your recovery code is incorrect!"}
              type="error"
            />
          )
        }
        const { masterKey, passwordHash } =
          await generateMasterKeyAndPasswordHashed(request.password, email)
        newRequest.password = passwordHash
        newRequest.confirmedPassword = passwordHash
        newRequest.encryptedVaultKey = encryptionHelper.encrypt(
          masterKey,
          vaultKey
        ) as string
        newRequest.masterKey = masterKey
      }
    } catch (error) {
      return toast(
        <LabelNotificationPage
          messenger={"Your recovery code is incorrect!"}
          type="error"
        />
      )
    }
    try {
      const captchaToken = executeRecaptcha
        ? await executeRecaptcha(ACTION_RECAPTCHA.PASSWORD_RESET)
        : ""
      const dataRequest: PasswordResetRequest = {
        ...newRequest,
        captcha: captchaToken,
        resetPasswordToken: newResetPasswordToken,
      }
      umamiTracking(EVENT.FORGOT_PASSWORD)
      passwordResetMiddleware(
        dataRequest,
        (type: STATUS_RESPONSE, messenger) => {
          isLoading.setValue(false)
          if (type === STATUS_RESPONSE.SUCCESS) {
            pushTo(PATH.resetSuccess)
          } else {
            toast(
              <LabelNotificationPage messenger={messenger ?? ""} type="error" />
            )
          }
        }
      )
    } catch (error) {
      isLoading.setValue(false)
    }
  }
  const onClickButton = async () => {
    if (!executeRecaptcha) {
      return toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
    }
    if (mfaMethods.value.length > 0) {
      //show 2fa form
      if (mfaMethods.value.includes(MFAMethod.email)) {
        resendCodeTwoFactor(newResetPasswordToken, true)
      }

      isShowMFAForm.setValue(true)
    } else {
      onSubmitResetPassword()
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        {isShowMFAForm.value ? (
          <TwoFactorForm
            oneTimeToken={newResetPasswordToken}
            setOneTimeToken={() => {}}
            mfaMethods={mfaMethods.value}
            isResetPassword={true}
            onVerifySuccess={onSubmitResetPassword}
          />
        ) : (
          <div>
            <Header title="Set new password" />
            <div className="my-6 ">
              <FormInputCard
                title="New Password"
                isTippy
                label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
              >
                <InputDefault
                  type="password"
                  isPassword
                  value={request.password}
                  onChange={handleChangeInput("password")}
                  onKeyPress={onKeyPress}
                  status={statusInputPassword.status}
                  labelStatus={statusInputPassword.label}
                />
              </FormInputCard>
              <FormInputCard title="Confirm New Password">
                <InputDefault
                  type="password"
                  isPassword
                  value={request.confirmedPassword}
                  onChange={handleChangeInput("confirmedPassword")}
                  onKeyPress={onKeyPress}
                  status={statusInputConfirm.status}
                  labelStatus={statusInputConfirm.label}
                />
              </FormInputCard>
            </div>

            <Button
              title={mfaMethods.value.length > 0 ? "Continue" : "Save"}
              onClick={onClickButton}
              isDisabledBtn
              widthBtn="100%"
              disabled={handleDisableButton()}
            />
          </div>
        )}
      </div>
    </PageLayoutAuth>
  )
}

export default PasswordReset
