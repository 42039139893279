import { encryptionHelper } from "helpers/encryption"
import { isEmpty } from "lodash"
import { IWorkerLocalStorage } from "./type"
import { EncryptionKeys } from "controllers/EncryptionController"

const getEncryptionKey = (
  localStorage: IWorkerLocalStorage,
  options: {
    type:
      | "conversation"
      | "conversation_note"
      | "project"
      | "build_additional_info"
    dataType: "string" | "file"
    encryptionKey?: string
    relationId?: string
  }
) => {
  let key = options.encryptionKey
  if (isEmpty(key)) {
    switch (options.type) {
      case "project":
        key = localStorage.currentProjectEncryptionKey
        if (options.relationId) {
          key = localStorage.projectEncryptionKeys[options.relationId]
        }
        break
      case "conversation":
        key = localStorage.currentConversationEncryptionKey
        if (options.relationId) {
          key = localStorage.conversationEncryptionKeys[options.relationId]
        }
        break

      case "conversation_note":
        key = localStorage.conversationNoteEncryptionKey
        break
      case "build_additional_info":
        key = ""
        if (options.relationId) {
          key =
            localStorage.buildAdditionalInfoEncryptionKeys[options.relationId]
        }
        break
      default:
        key = ""
        break
    }
  }
  return key
}

export const encryptFile = async (
  localStorageData: IWorkerLocalStorage,
  data: any,
  options: {
    type:
      | "conversation"
      | "conversation_note"
      | "project"
      | "build_additional_info"
    dataType: "string" | "file"
    encryptionKey?: string
    relationId?: string
  }
) => {
  //get key
  const key = getEncryptionKey(localStorageData, options)
  if (!key || isEmpty(key)) {
    return data
  }
  if (options.dataType === "string") {
    return encryptionHelper.encrypt(key, data) as string
  }

  const readFile = await encryptionHelper.readFile(data)
  let fileArrayBuffer = new Uint8Array(readFile as any)
  const temp = encryptionHelper.toWordArray(fileArrayBuffer)
  return encryptionHelper.encrypt(key, temp) as string
}

export const getWorkerLocalStorageData = (): IWorkerLocalStorage => {
  return {
    currentProjectEncryptionKey: localStorage.getItem(
      EncryptionKeys.currentProjectEncryptionKey
    ) as string,
    projectEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    ),
    currentConversationEncryptionKey: localStorage.getItem(
      EncryptionKeys.currentConversationEncryptionKey
    ) as string,
    conversationEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    ),
    conversationNoteEncryptionKey: localStorage.getItem(
      EncryptionKeys.conversationNoteEncryptionKey
    ) as string,
    buildAdditionalInfoEncryptionKeys: JSON.parse(
      localStorage.getItem(EncryptionKeys.buildAdditionalInfoEncryptionKeys) ||
        "{}"
    ),
  }
}

export const encryptContent = async (
  localStorageData: any,
  content: any,
  contentOption: any
) => {
  return await encryptFile(localStorageData, content, contentOption)
}

export const encryptSVGs = async (localStorageData: any, dataSvgs: any[]) => {
  const svgRequest: { fileName: string; blob: Blob }[] = []
  const svgs = await Promise.all(
    dataSvgs.map(async (svg) => {
      const svgBlob = new Blob([svg.content], { type: "image/svg+xml" })
      const encryptedHtmlFile = await encryptFile(localStorageData, svgBlob, {
        dataType: "file",
        type: "project",
      })
      svgRequest.push({
        blob: new Blob([encryptedHtmlFile], { type: svgBlob.type }),
        fileName: svg.name,
      })
      const { content, ...rest } = svg
      return rest
    })
  )
  return { svgs, svgRequest }
}

export const encryptFiles = async (
  localStorageData: any,
  files: any[],
  fileOption: any,
  isRawFile: boolean
) => {
  const attachments: { fileName: string; blob: Blob }[] = []
  let fileString = ""
  const newFileHash = await Promise.all(
    files.map(async (file) => {
      const encryptedFile = await encryptFile(
        localStorageData,
        isRawFile ? file : file.file,
        fileOption
      )

      attachments.push({
        blob: new Blob([encryptedFile], {
          type: isRawFile ? file.type : file.file.type,
        }),
        fileName: isRawFile ? file.name : file.file_name,
      })

      fileString += isRawFile ? file.name : file.file_name + ","

      let fileText: any = null
      if (isRawFile) {
        const rawFileBlob = new Blob([file], { type: file.type })
        fileText = await rawFileBlob.text()
      } else {
        fileText = await file.file.text()
      }

      if (isRawFile) {
        return {
          file_name: file.name,
          file_hash: encryptionHelper.createFileHash(fileText),
        }
      }

      return {
        ...file,
        file_hash: encryptionHelper.createFileHash(fileText),
      }
    })
  )
  return { newFileHash, attachments, fileString }
}
