import crypto from "crypto-js"
import { generate } from "random-words"
import moment from "moment"
import forge from "node-forge"

class EncryptionHelper {
  constructor() {}

  /**
   * Function for creating two way encryption
   * @param encryptionKey string
   * @param rawData string
   * @returns string
   */
  public encrypt = (encryptionKey: string, rawData: any) => {
    if (rawData === "") return rawData
    const temp = this.createRandomKey()
    return crypto.AES.encrypt(rawData, encryptionKey, {
      iv: crypto.enc.Hex.parse(temp),
    }).toString()
  }

  /**
   * Function for decrypting cipher to the plain text
   * @param encryptionKey string
   * @param encryptedData string
   * @returns string
   */
  public decrypt = (
    encryptionKey: string,
    encryptedData: string,
    fileMode?: boolean
  ) => {
    if (encryptedData === "") return encryptedData
    try {
      const temp = this.createRandomKey()
      const decrypted = crypto.AES.decrypt(
        encryptedData.toString(),
        encryptionKey,
        {
          iv: crypto.enc.Hex.parse(temp),
        }
      )
      if (fileMode) {
        return decrypted
      }
      const result = decrypted.toString(crypto.enc.Utf8)
      if (result === "") {
        return encryptedData
      }
      return result
    } catch (error) {
      console.log(error)
      return encryptedData
    }
  }

  /**
   * Function for creating a PBKDF2 key with specified bits
   * @param bits 128 | 256 | 512
   * @returns string
   */
  public createRandomKey = (bits: 128 | 256 | 512 = 256) => {
    return crypto.lib.WordArray.random(bits / 8).toString(crypto.enc.Hex)
  }

  /**
   * Function for one way encryption
   * @param key string
   * @param salt string
   * @returns string
   */
  public hashKey = (key: string, salt: string) => {
    return crypto.HmacSHA256(key, salt).toString()
  }

  /**
   * Function for creating a simple mnemonic key for backup and recovery
   * @returns string
   */
  public createBackupKey = () => {
    return (generate(12) as string[]).join(" ")
  }

  /**
   * Function for creating the encryted share key
   * @param accessToken string
   * @param encryptionKey string
   * @param options any
   * @returns
   */
  public createEncryptedShareKey = (
    accessToken: string,
    encryptionKey: string,
    options: {
      period: number
      unit: moment.unitOfTime.DurationConstructor
    }
  ) => {
    const artifact = {
      encryptionKey,
      expiredAt: moment().add(options.period, options.unit).unix(),
    }
    const rawData = JSON.stringify(artifact)
    return this.encrypt(accessToken, rawData)
  }

  /**
   * Function for validate and decrypt the encryption share key
   * @param accessToken string
   * @param encryptedData string
   * @returns
   */
  public validateEncrytedShareKey = (
    accessToken: string,
    encryptedData: string
  ) => {
    const rawData = this.decrypt(accessToken, encryptedData) as string
    const artifact = JSON.parse(rawData)
    const nowUnix = moment().unix()
    if (nowUnix >= artifact.expiredAt) {
      return {
        status: false,
        data: "The access is expired!",
      }
    }
    return {
      status: true,
      data: artifact.encryptionKey,
    }
  }
  public toUint8Array = (data: any) => {
    const dataArray = new Uint8Array(data.sigBytes)
    for (let i = 0x0; i < data.sigBytes; i++) {
      dataArray[i] = (data.words[i >>> 0x2] >>> (0x18 - (i % 0x4) * 0x8)) & 0xff
    }
    return new Uint8Array(dataArray)
  }

  public readFile(file) {
    return new Promise((res, rej) => {
      // create file reader
      let reader = new FileReader()

      // register event listeners
      reader.addEventListener("loadend", (e) => res(e?.target?.result))
      reader.addEventListener("error", rej)

      // read file
      reader.readAsArrayBuffer(file)
    })
  }

  public getPBKDFHash = (
    payload: string,
    salt: string,
    iterations = 1,
    keySize = 8
  ) => {
    const derivedKey = crypto.PBKDF2(payload, salt, {
      keySize,
      iterations,
      hasher: crypto.algo.SHA256,
    })
    return derivedKey.toString(crypto.enc.Hex)
  }

  public toWordArray = (input: any) => {
    return crypto.lib.WordArray.create(input)
  }
  public createFileHash = (input: any) => {
    return crypto.MD5(input).toString()
  }

  public generateKeyPair = () => {
    const { pki } = forge
    return new Promise<{ publicKey: string; privateKey: string }>((resolve) => {
      try {
        const keypair = pki.rsa.generateKeyPair(2048)
        const publicKeyPem = pki.publicKeyToPem(keypair.publicKey)
        const privateKeyPem = pki.privateKeyToPem(keypair.privateKey)
        resolve({
          publicKey: publicKeyPem,
          privateKey: privateKeyPem,
        })
      } catch (error) {
        resolve({
          publicKey: "",
          privateKey: "",
        })
      }
    })
  }
}

export const encryptionHelper = new EncryptionHelper()
